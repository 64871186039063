import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { CreateUserPage } from './components/pages/users/create/CreateUserPage'
import { UserListPage } from './components/pages/users/list/UserListPage'
import { ToastProvider } from './contexts/ToastContext'
import { NavBar } from './NavBar'
import { CreateAppPage } from './components/pages/apps/create/CreateAppPage'
import { AppDetailPage } from './components/pages/apps/detail/AppDetailPage'
import { AppListPage } from './components/pages/apps/list/AppListPage'
import { WholesalerDetailPage } from './components/pages/wholesaler/detail/WholesalerDetailPage'
import { WholesalerListPage } from './components/pages/wholesaler/list/WholesalerListPage'
import { WorkshopCreatePage } from './components/pages/workshops/create/WorkshopCreatePage'
import { WorkshopDetailPage } from './components/pages/workshops/detail/WorkshopDetailPage'
import { WorkshopPage } from './components/pages/workshops/list/WorkshopPage'
import { CreateCollectionPage } from './components/pages/collection-pages/create/CreateCollectionPage'
import { CollectionPageDetail } from './components/pages/collection-pages/detail/CollectionPageDetail'
import { CollectionPageList } from './components/pages/collection-pages/list/CollectionPageList'
import { HelpPage } from './components/pages/help/HelpPage'
import styled from 'styled-components'
import { WebOrderPage } from './components/pages/weborder/WebOrderPage'
import { UpdateTiresPage } from './components/pages/wholesaler/update-tires/UpdateTiresPage'

const Container = styled.div`
  max-width: 1227px;
  margin-left: auto;
  margin-right: auto;
`

export const AuthenticatedApp = () => {
  return (
    <ToastProvider>
      <NavBar />

      <Container>
        <Switch>
          <Route path="/users/create">
            <CreateUserPage />
          </Route>
          <Route path="/users">
            <UserListPage />
          </Route>
          <Route path="/apps/create">
            <CreateAppPage />
          </Route>
          <Route path="/apps/:id">
            <AppDetailPage />
          </Route>
          <Route path="/apps">
            <AppListPage />
          </Route>
          <Route path="/wholesalers/:id/update-tires">
            <UpdateTiresPage />
          </Route>
          <Route path="/wholesalers/:id">
            <WholesalerDetailPage />
          </Route>
          <Route path="/wholesalers">
            <WholesalerListPage />
          </Route>
          <Route path="/workshops/create">
            <WorkshopCreatePage />
          </Route>
          <Route path="/workshops/:id">
            <WorkshopDetailPage />
          </Route>
          <Route path="/workshops">
            <WorkshopPage />
          </Route>
          <Route path="/collection-pages/create">
            <CreateCollectionPage />
          </Route>
          <Route path="/collection-pages/:id">
            <CollectionPageDetail />
          </Route>
          <Route path="/collection-pages">
            <CollectionPageList />
          </Route>
          <Route path="/web-orders">
            <WebOrderPage />
          </Route>
          <Route path="/help">
            <HelpPage />
          </Route>
          <Redirect to="/workshops" />
        </Switch>
      </Container>
    </ToastProvider>
  )
}
