import React, { useEffect, useState } from 'react'
import { AppListItem } from './AppListItem'
import { AppList } from './AppList'
import { Error } from '../../../shared/Error'
import { PageHeader } from '../../../shared/PageHeader'
import { Loading } from '../../../shared/Loading'
import { sortByName } from '../../../../lib/helpers'
import { client } from '../../../../lib/client'

export const AppListPage = () => {
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>('pending')
  const [apps, setApps] = useState<any[]>([])
  const [error, setError] = useState<string>('')

  useEffect(() => {
    setStatus('pending')

    client(`app`)
      .then(({ results }) => {
        setApps(results)
        setStatus('idle')
      })
      .catch(() => {
        setError('Det oppstod en feil')
        setStatus('failure')
      })
  }, [])

  const items = apps
    ? apps.sort(sortByName).map((app) => <AppListItem key={app.app_id} app={app} />)
    : []

  return (
    <div className="grid grid-cols-12 gap-x-md max-width mx-auto mt-md">
      <PageHeader title="Apper" newPath="/apps/create" />

      {error && <Error className="col-span-12">{error}</Error>}

      {status === 'pending' ? (
        <div className="col-span-12 mt-md flex items-center justify-center">
          <Loading />
        </div>
      ) : (
        <div className="col-span-12">
          <AppList>{items}</AppList>
        </div>
      )}
    </div>
  )
}
