import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { WorkshopDetailForm } from './WorkshopDetailForm'
import { HandleApps } from '../../../shared/HandleApps'
import { Error } from '../../../shared/Error'
import { Meta } from '../common/Meta'
import { Images } from '../common/Images'
import { WorkshopAppSettings } from '../common/WorkshopAppSettings'
import { PageHeader } from '../../../shared/PageHeader'
import { Tab } from '../../../shared/Tabs'
import { Loading } from '../../../shared/Loading'
import { WorkshopCollectionPages } from './WorkshopCollectionPages'
import { WorkshopHistory } from '../common/WorkshopHistory'
import { StructuredData } from '../common/StructuredData'
import { ToastContext } from '../../../../contexts/ToastContext'
import { client } from '../../../../lib/client'
import { ServicesPage } from './ServicesPage'
import { JobPage } from './JobPage'

export const WorkshopDetailPage = () => {
  const history = useHistory()
  const location = useLocation()
  let { id } = useParams<{ id: string }>()
  const { showToast } = useContext(ToastContext)
  const tabItems = [
    'Info',
    'Apper',
    'Bilder',
    'Innstillinger',
    'Historikk',
    'Strukturerte data',
    'TJENESTER',
    'STILLINGER',
  ]
  const initialTab = location.search ? decodeURI(location.search.replace('?tab=', '')) : tabItems[0]

  const [tab, setTab] = useState(initialTab)
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>('idle')
  const [workshop, setWorkshop] = useState<any>(undefined)

  useEffect(() => {
    setStatus('pending')

    client(`workshop/${id}`)
      .then((response) => {
        setStatus('idle')
        setWorkshop(response)
      })
      .catch((error) => {
        console.log(error)
        setStatus('failure')
      })
  }, [id])

  const updateWorkshop = (data: any) => {
    client(`workshop/${id}`, { method: 'PATCH', body: data })
      .then((response) => {
        setWorkshop(response)
        showToast({ type: 'SUCCESS', message: 'Lagret!' })
      })
      .catch((error) => {
        console.log(error)
        showToast({ type: 'ERROR', message: 'Det oppstod dessverre en feil' })
      })
  }

  if (status === 'failure') {
    return <Error>Det oppstod dessverre en feil.</Error>
  }

  if (workshop) {
    return (
      <React.Fragment>
        <PageHeader
          title={workshop.name ?? ''}
          parent={{ title: 'Verksteder', path: '/workshops' }}
        />

        <div className="grid grid-cols-12 gap-x-md">
          <div className="col-span-3">
            <div aria-label="verksted tabber">
              {tabItems.map((item, i) => (
                <Tab
                  key={`tab-${i}`}
                  label={item}
                  active={item === tab}
                  onClick={(newTab) => {
                    history.push(`?tab=${newTab}`)
                    setTab(newTab)
                  }}
                  aria-controls={`tabpanel-${tab.toLowerCase()}`}
                />
              ))}
            </div>
          </div>
          <div className="col-span-9">
            {tab === 'Info' && <WorkshopDetailForm workshop={workshop} />}

            {tab === 'Apper' && <HandleApps type="workshop" id={id} />}

            {tab === 'Bilder' && <Images workshopId={id} />}

            {tab === 'Innstillinger' && (
              <React.Fragment>
                <div className="flex gap-md">
                  <div className="w-1/2">
                    <Meta workshopId={id} />
                  </div>

                  <div className="w-1/2">
                    <WorkshopAppSettings id={id} />
                  </div>
                </div>

                <WorkshopCollectionPages workshopId={id} />
              </React.Fragment>
            )}

            {tab === 'Historikk' && <WorkshopHistory dist={workshop.dist} />}

            {tab === 'Strukturerte data' && (
              <StructuredData workshop={workshop} onDataChanged={updateWorkshop} />
            )}

            {tab === 'TJENESTER' && <ServicesPage workshop={workshop} />}

            {tab === 'STILLINGER' && <JobPage workshop={{ workshop }} />}
          </div>
        </div>
      </React.Fragment>
    )
  }

  return (
    <div className="flex items-center justify-center">
      <Loading />
    </div>
  )
}
