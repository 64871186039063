import React, { useEffect, useState } from 'react'
import { PageHeader } from '../../shared/PageHeader'
import { client } from '../../../lib/client'
import moment from 'moment'
import { Loading } from '../../shared/Loading'
import { Error } from '../../shared/Error'
import { sortByName } from '../../../lib/helpers'

const dateFormat = 'YYYY-MM-DD'

const calcTotalByDeliveryType = (workshops: any[], deliveryType: string | null) =>
  workshops?.length > 0
    ? workshops
        .map(
          (workshop) =>
            workshop.orders.filter((order: any) => order.delivery === deliveryType).length
        )
        .reduce((a, b) => a + b, 0)
    : 0

export const WebOrderPage = () => {
  const start = moment().startOf('month').format(dateFormat)
  const end = moment().endOf('month').format(dateFormat)
  const [dateRange, setDateRange] = useState<{ start: string; end: string }>({ start, end })
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>('idle')
  const [workshops, setWorkshops] = useState<any[]>([])
  const nextMonth = moment(start).add(1, 'month').format('MMMM')

  const today = () => setDateRange({ start, end })

  const dateRangeIsCurrentMonth =
    moment(dateRange.start).format('YYYY-MMMM') === moment().format('YYYY-MMMM')

  const prev = () => {
    setDateRange({
      start: moment(dateRange.start).subtract(1, 'month').format(dateFormat),
      end: moment(dateRange.start).subtract(1, 'month').endOf('month').format(dateFormat),
    })
  }

  const next = () => {
    setDateRange({
      start: moment(dateRange.start).add(1, 'month').format(dateFormat),
      end: moment(dateRange.start).add(1, 'month').endOf('month').format(dateFormat),
    })
  }

  useEffect(() => {
    setStatus('pending')

    client(`web-order?startAt=${dateRange.start}&endAt=${dateRange.end}`)
      .then((results) => {
        setStatus('idle')
        setWorkshops(results)
      })
      .catch((error) => {
        setStatus('failure')
      })
  }, [dateRange])

  const totalCars = calcTotalByDeliveryType(workshops, 'cars')
  // Default to 'cars' if delivery is null (pre feb. 2021)
  const totalNull = calcTotalByDeliveryType(workshops, null)
  const totalEmail = calcTotalByDeliveryType(workshops, 'email')

  return (
    <div className="grid grid-cols-12 gap-x-md max-width mx-auto mt-md">
      <PageHeader title="Timebestillinger" />

      <div className="col-span-6">
        <button className="btn btn-secondary" onClick={prev}>
          Forrige mnd.
        </button>
        <button className="btn btn-secondary" onClick={today} disabled={dateRangeIsCurrentMonth}>
          Denne mnd.
        </button>
        <button className="btn btn-secondary" onClick={next} disabled={dateRangeIsCurrentMonth}>
          {nextMonth}
        </button>
      </div>

      {status === 'pending' && (
        <div className="col-span-12 mt-md flex items-center justify-center">
          <Loading />
        </div>
      )}

      {status === 'failure' && <Error className="col-span-12">Det oppstod dessverre en feil</Error>}

      {workshops.length > 0 ? (
        <div className="col-span-12">
          <h4 className="heading-lg">
            {moment(dateRange.start).format('D. MMMM YYYY')} -{' '}
            {moment(dateRange.end).format('D. MMMM YYYY')}
          </h4>

          <table className="table">
            <thead>
              <tr>
                <th>DIST</th>
                <th>Navn</th>
                <th>Cars</th>
                <th>E-post</th>
              </tr>
            </thead>
            <tbody>
              {workshops.sort(sortByName).map((workshop: any, i: number) => (
                <tr key={`order-${i}`}>
                  <td>{workshop.dist}</td>
                  <td>{workshop.name}</td>
                  <td>
                    {
                      workshop.orders.filter(
                        (order: any) => order.delivery === 'cars' || !order.delivery
                      ).length
                    }
                  </td>
                  <td>
                    {workshop.orders.filter((order: any) => order.delivery === 'email').length}
                  </td>
                </tr>
              ))}

              <tr>
                <th>Totalt</th>
                <th />
                <th>{totalCars + totalNull}</th>
                <th>{totalEmail}</th>
              </tr>
            </tbody>
          </table>
        </div>
      ) : null}

      {status !== 'pending' && workshops.length === 0 && (
        <p className="col-span-12 mt-md">Ingen ordre i denne perioden</p>
      )}
    </div>
  )
}
