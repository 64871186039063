import { LoginPage } from './components/pages/login/LoginPage'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

export const UnauthenticatedApp = () => {
  return (
    <Switch>
      <Route exact path="/login" component={LoginPage} />
      <Redirect to="/login" />
    </Switch>
  )
}
