import React, { useEffect, useState } from 'react'
import { WholesalerListItem } from './WholesalerListItem'
import { WholesalerList } from './WholesalerList'
import { Error } from '../../../shared/Error'
import { sortByName } from '../../../../lib/helpers'
import { PageHeader } from '../../../shared/PageHeader'
import { Filter } from '../../../shared/Filter'
import { Loading } from '../../../shared/Loading'
import { client } from '../../../../lib/client'

export const WholesalerListPage = () => {
  const [status, setStatus] = useState('idle')
  const [wholesalers, setWholesalers] = useState<any[]>([])
  const [filteredWholesalers, setFilteredWholesalers] = useState<any[]>([])

  useEffect(() => {
    setStatus('pending')

    client(`wholesale`)
      .then((result) => {
        setStatus('idle')
        setWholesalers(result)
        setFilteredWholesalers(result)
      })
      .catch(() => {
        setStatus('failure')
      })
  }, [])

  const items = filteredWholesalers
    ? filteredWholesalers
        .sort(sortByName)
        .map((wholesaler) => (
          <WholesalerListItem key={wholesaler.wholesaler_id} wholesaler={wholesaler} />
        ))
    : []

  return (
    <div className="grid grid-cols-12 gap-x-md max-width mx-auto mt-md">
      <PageHeader title="Grossist" />

      {status === 'failure' && <Error className="col-span-12">Det oppstod dessverre en feil</Error>}

      {status === 'pending' ? (
        <div className="col-span-12 mt-md flex items-center justify-center">
          <Loading />
        </div>
      ) : (
        <React.Fragment>
          <div className="col-span-3">
            <Filter
              data={wholesalers}
              filterChanged={(filtered) => {
                setFilteredWholesalers(filtered)
              }}
            />
          </div>

          <div className="col-span-12">
            <WholesalerList>{items}</WholesalerList>
          </div>
        </React.Fragment>
      )}
    </div>
  )
}
