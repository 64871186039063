import React, { useState } from 'react'
import { PageHeader } from '../../../shared/PageHeader'
import { UserForm } from '../common/UserForm'
import { useHistory } from 'react-router-dom'
import { Error } from '../../../shared/Error'
import { client } from '../../../../lib/client'

export const CreateUserPage = () => {
  const history = useHistory()
  const user: any = { username: '', role: 'SUPERUSER' }
  const [status, setStatus] = useState('idle')

  const onSubmit = async (newUser: any) => {
    setStatus('pending')

    client(`user`, {
      method: 'POST',
      body: newUser,
    })
      .then(() => {
        history.push(`/users/${user.id}`)
      })
      .catch(() => {
        setStatus('failure')
      })
  }

  return (
    <React.Fragment>
      <PageHeader title="Ny bruker" parent={{ path: '/users', title: 'Brukere' }} />
      {status === 'failure' && <Error>Det oppstod dessverre en feil</Error>}
      {status === 'pending' && <p>Oppretter bruker...</p>}
      {status !== 'pending' && (
        <UserForm user={user} onSubmit={onSubmit} cancel={() => history.goBack()} />
      )}
    </React.Fragment>
  )
}
