import React, { FunctionComponent, useState } from 'react'
import { client } from '../../../../lib/client'
import { Loading } from '../../../shared/Loading'
import { Error } from '../../../shared/Error'
import styled from 'styled-components'

const Label = styled.label`
  &:active {
    background: white;
  }

  input {
    width: 0;
    height: 0;
    visibility: hidden;
  }
`

type Props = {
  wholesalerId: string
  onFileUploaded: () => void
}

export const FileUpload: FunctionComponent<Props> = ({ wholesalerId, onFileUploaded }) => {
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>('idle')

  const onFileChanged = async (e: any) => {
    setStatus('pending')

    // @ts-ignore
    const file = e.target.files[0]

    const filePath: any = URL.createObjectURL(file)
    const resp = await fetch(filePath)
    const blob = await resp.blob()

    const uploadToS3 = (url: string) =>
      fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': blob.type,
        },
        body: blob,
      })

    client(`wholesale/${wholesalerId}/tire-upload-url`, { body: {} })
      .then(({ url }) => uploadToS3(url))
      .then(() => {
        onFileUploaded()
        setStatus('idle')
      })
      .catch((error) => {
        console.log(error)
        setStatus('failure')
      })
  }

  return (
    <React.Fragment>
      {status === 'failure' && <Error>Det oppstod dessverre en feil</Error>}

      <Label className="border rounded text-center w-auto py-sm px-md bg-primary text-white transition">
        {status === 'pending' ? <Loading size="small" /> : <span>Last opp ny dekkfil</span>}
        <input type="file" accept=".txt" onChange={onFileChanged} />
      </Label>
    </React.Fragment>
  )
}
