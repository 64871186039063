import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

const CheckBoxStyled = styled.input`
  margin-right: calc(var(--leading) / 2);
`

type Props = {
  label: string
  checked: boolean
  onChange: (checked: boolean) => void
  disabled?: boolean
}

export const CheckBox: FunctionComponent<Props> = ({ label, checked, onChange, disabled }) => {
  return (
    <label>
      <CheckBoxStyled
        type="checkbox"
        checked={checked}
        onChange={({ target: { checked } }) => onChange(checked)}
        disabled={disabled}
      />
      {label}
    </label>
  )
}

type CheckboxProps = {
  label: string
  checked: boolean
  //   onChange: (checked: boolean) => void
  onClick: () => void
  disabled?: boolean
}

export const SimpleCheckBox: FunctionComponent<CheckboxProps> = ({
  label,
  checked,
  //   onChange,
  disabled,
  onClick,
}) => {
  return (
    <label>
      <CheckBoxStyled
        type="checkbox"
        checked={checked}
        readOnly={true}
        // onChange={({ target: { checked } }) => onChange(checked)}
        onClick={onClick}
        disabled={disabled}
      />
      {label}
    </label>
  )
}
