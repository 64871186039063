import { useState } from 'react'
import { omit } from 'lodash'

const useForm = ({ workshop_id, email, title, description }, callback) => {
  const [values, setValues] = useState({ workshop_id, email, title, description })
  const [errors, setErrors] = useState({})

  const validate = (name, value) => {
    switch (name) {
      case 'title':
        if (value.length <= 4) {
          setErrors({
            ...errors,
            title: 'Title must have at least 5 characters',
          })
        } else {
          let newObj = omit(errors, 'title')
          setErrors(newObj)
        }
        break

      case 'email':
        if (
          !new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ).test(value)
        ) {
          setErrors({
            ...errors,
            email: 'Enter a valid email address',
          })
        } else {
          let newObj = omit(errors, 'email')
          setErrors(newObj)
        }
        break

      case 'description':
        if (value.length <= 40) {
          setErrors({
            ...errors,
            description: 'Description must have at least 40 characters',
          })
        } else {
          let newObj = omit(errors, 'description')
          setErrors(newObj)
        }
        break

      default:
        break
    }
  }

  const validateField = (name, value) => {
    validate(name, value)

    setValues({
      ...values,
      [name]: value,
    })
  }

  const handleSubmit = (event) => {
    if (event) event.preventDefault()

    if (Object.keys(errors).length === 0 && Object.keys(values).length !== 0) {
      callback()
    }
  }

  return {
    values,
    errors,
    validateField,
    handleSubmit,
  }
}

export default useForm
