import React from 'react'
import moment from 'moment'

type Props = {
  jobs: any
  // eslint-disable-next-line no-empty-pattern
  onEdit: ({}) => void
}
export const JobList = (props: Props) => {
  const truncateText = (str: string, n: number) => {
    return str.length > n ? str.slice(0, n - 1) + '...' : str
  }

  return (
    <div className="flex">
      <table className="table" aria-label="verksteder">
        <thead>
          <tr>
            <th>Workshop</th>
            <th>Tittel</th>
            <th>Stillingsannonse tekst</th>
            <th>E-post</th>
            <th>Status</th>
            <th>Lag Dato</th>
          </tr>
        </thead>
        <tbody>
          {props.jobs?.map((job: any) => (
            <tr className="cursor-pointer" key={`w-${job.id}`} onClick={() => props.onEdit(job)}>
              <td>{job.workshop}</td>
              <td>{job.title}</td>
              <td dangerouslySetInnerHTML={{ __html: truncateText(job.description, 20) }}></td>
              <td>{job.email}</td>
              <td>{job.status}</td>
              <td>{moment(job.create_date).format('DD/MM/YYYY')}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
