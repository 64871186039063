import React, { FunctionComponent } from 'react'

declare global {
  type S3Object = {
    Key: string
    LastModified: string
    ETag: string
    Size: number
    StorageClass: string
  }
}

type Props = {
  files: S3Object[]
}

export const ExistingTireFiles: FunctionComponent<Props> = ({ files }) => {
  return (
    <React.Fragment>
      {files.length === 0 ? (
        <p>Ingen filer lastet opp ennå</p>
      ) : (
        <table className="table">
          <thead>
            <th>Navn</th>
            <th>Sist oppdatert</th>
          </thead>

          <tbody>
            {files.map((file) => (
              <tr>
                <td>{file.Key}</td>
                <td>{new Date(file.LastModified).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </React.Fragment>
  )
}
