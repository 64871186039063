import React, { FunctionComponent } from 'react'

export const AppList: FunctionComponent = ({ children }) => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th>Navn</th>
          <th>Aktiv</th>
          <th />
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  )
}
