import React, { FunctionComponent, useEffect, useState } from 'react'
import moment from 'moment'
import { Error } from '../../../shared/Error'
import { Loading } from '../../../shared/Loading'
import { client } from '../../../../lib/client'

const dateFormat = 'YYYY-MM-DD'

type Props = {
  id: string
}

export const AdItemOrders: FunctionComponent<Props> = ({ id }) => {
  const start = moment().startOf('month').format(dateFormat)
  const end = moment().endOf('month').format(dateFormat)
  const [dateRange, setDateRange] = useState<{ start: string; end: string }>({ start, end })
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>('idle')
  const [orders, setOrders] = useState<any[]>([])

  const today = () => setDateRange({ start, end })

  const dateRangeIsCurrentMonth =
    moment(dateRange.start).format('YYYY-MMMM') === moment().format('YYYY-MMMM')

  const prev = () => {
    setDateRange({
      start: moment(dateRange.start).subtract(1, 'month').format(dateFormat),
      end: moment(dateRange.start).subtract(1, 'month').endOf('month').format(dateFormat),
    })
  }

  const next = () => {
    setDateRange({
      start: moment(dateRange.start).add(1, 'month').format(dateFormat),
      end: moment(dateRange.start).add(1, 'month').endOf('month').format(dateFormat),
    })
  }

  useEffect(() => {
    setStatus('pending')

    client(`ad-item/${id}/orders?start=${dateRange.start}&end=${dateRange.end}`)
      .then(({ results }) => {
        setStatus('idle')
        setOrders(results)
      })
      .catch((error) => {
        setStatus('failure')
      })
  }, [dateRange, id])

  return (
    <React.Fragment>
      <h3 className="text-lg mb-md">{moment(dateRange.start).format('MMMM YYYY')}</h3>

      <div className="flex">
        <div>
          <button className="btn btn-primary" onClick={prev}>
            Forrige mnd.
          </button>
        </div>
        <div>
          <button className="btn btn-primary" onClick={today} disabled={dateRangeIsCurrentMonth}>
            Denne mnd.
          </button>
        </div>
        <div>
          <button className="btn btn-primary" onClick={next} disabled={dateRangeIsCurrentMonth}>
            Neste mnd.
          </button>
        </div>
      </div>

      {status === 'pending' && (
        <div className="flex items-center justify-center">
          <Loading />
        </div>
      )}

      {status === 'failure' && <Error>Det oppstod dessverre en feil</Error>}

      {orders.length > 0 ? (
        <table className="table">
          <thead>
            <tr>
              <th>Dato</th>
              <th>Kunde</th>
              <th>App</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order, i) => (
              <tr key={`order-${i}`}>
                <td>{new Date(order.created_at).toLocaleString()}</td>
                <td>{order.workshop_name}</td>
                <td>{order.app_name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : null}

      {status !== 'pending' && orders.length === 0 && <p>Ingen ordre i denne perioden</p>}
    </React.Fragment>
  )
}
