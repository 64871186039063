import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { ParentPageSelector } from './ParentPageSelector'
import { sortByName } from '../../../../lib/helpers'
import { HierarchyListButton } from './HierarchyListButton'

const HierarchyList = styled.ul`
  list-style: none;

  ul {
    padding-left: 1rem;
  }
`

const ViewLink = styled(Link)`
  margin-right: 1rem;

  &:link,
  &:visited {
    color: inherit;
    border-bottom: 1px solid var(--primary);
  }
`

const HierarchyListItemInner = styled.div`
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #cccccc;
`

const ButtonGroup = styled.div`
  button:not(:first-child) {
    margin-left: 1rem;
  }
`

type Page = { parent_id: string; level: number; page_id: string; name: string; hidden: boolean }

type Props = {
  all: Page[]
  pages: Page[]
  onDelete: (page: Page) => void
  removeParentPage: (pageId: string) => void
  updatePage: (page: Page) => void
}

export const PageList: FunctionComponent<Props> = ({
  all,
  pages,
  onDelete,
  removeParentPage,
  updatePage,
}) => {
  const [showChildrenOf, setShowChildrenOf] = useState<string>('')

  const changeParent = (page: Page, parentId: string | null) => {
    if (parentId === null) {
      removeParentPage(page.page_id)
    } else {
      updatePage({ ...page, parent_id: parentId })
    }
  }

  return (
    <HierarchyList>
      {pages.sort(sortByName).map((page) => {
        const children = all.filter((apage) => apage.parent_id === page.page_id)
        const hasChildren = children.length > 0
        const isShowingChildren = showChildrenOf === page.page_id

        return (
          <li>
            <HierarchyListItemInner>
              {children.length > 0 && (
                <HierarchyListButton
                  open={isShowingChildren}
                  toggle={() => setShowChildrenOf(isShowingChildren ? '' : page.page_id)}
                />
              )}

              <div className="flex-1">
                {page.name} ({children.length})
              </div>

              <div style={{ marginRight: '1.6rem' }}>
                {page.hidden ? (
                  <div className="text-error">Skjult</div>
                ) : (
                  <div className="text-success">Synlig</div>
                )}
              </div>

              <ButtonGroup>
                <ViewLink to={`/collection-pages/${page.page_id}`}>Vis</ViewLink>

                <ParentPageSelector
                  selected={page.parent_id}
                  pages={all.filter((p) => p.page_id !== page.page_id).sort(sortByName)}
                  onChange={(parentId) => changeParent(page, parentId)}
                />

                <button className="btn btn-danger-outline" onClick={() => onDelete(page)}>
                  Slett
                </button>
              </ButtonGroup>
            </HierarchyListItemInner>

            {hasChildren && isShowingChildren && (
              <PageList
                all={all}
                pages={children}
                onDelete={onDelete}
                updatePage={updatePage}
                removeParentPage={removeParentPage}
              />
            )}
          </li>
        )
      })}
    </HierarchyList>
  )
}
