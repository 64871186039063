import React, { FormEvent, FunctionComponent, useState } from 'react'
import { storage } from '../../firebase'
import { Error } from './Error'
import { Loading } from './Loading'
import { client } from '../../lib/client'

type Props = {
  uploadUrl: string
  onUploadCompleted: () => void
}

export const UploadImage: FunctionComponent<Props> = ({ uploadUrl, onUploadCompleted }) => {
  const [imageAsFile, setImageAsFile] = useState<any | string>('')
  const [status, setStatus] = useState('idle')

  const handleImageAsFile = (e: FormEvent) => {
    // @ts-ignore
    const image = e.target.files[0]
    setImageAsFile(image)
  }

  const handleFireBaseUpload = (e: FormEvent) => {
    e.preventDefault()

    setStatus('pending')

    if (imageAsFile === '') {
      console.error(`not an image, the image file is a ${typeof imageAsFile}`)
      setStatus('failure')
    } else {
      const uploadTask = storage.ref(`/images/${imageAsFile.name}`).put(imageAsFile)

      uploadTask.on(
        'state_changed',
        (snapShot) => console.log(snapShot),
        (err) => console.log(err),
        () => {
          storage
            .ref('images')
            .child(imageAsFile.name)
            .getDownloadURL()
            .then((fireBaseUrl) =>
              client(uploadUrl, { method: 'POST', body: { image_url: fireBaseUrl } })
            )
            .then(() => {
              setStatus('idle')
              onUploadCompleted()
              setImageAsFile('')
            })
            .catch((error) => {
              console.error(error)
              setStatus('failure')
            })
        }
      )
    }
  }

  return (
    <React.Fragment>
      {status !== 'pending' && (
        <div>
          <input type="file" onChange={handleImageAsFile} accept="image/*" />
        </div>
      )}

      {status === 'failure' && <Error>Bildeopplastning feilet.</Error>}

      {status === 'pending' && (
        <div className="flex items-center justify-center">
          <Loading />
        </div>
      )}

      <button
        style={{ marginTop: '1rem' }}
        className="btn btn-primary"
        disabled={!imageAsFile || status === 'pending'}
        onClick={handleFireBaseUpload}
      >
        {status === 'pending' ? 'Laster opp...' : 'Last opp bilde'}
      </button>
    </React.Fragment>
  )
}
