import React, { useState } from 'react'
import { PageHeader } from '../../../shared/PageHeader'
import { AppForm } from '../common/AppForm'
import { useHistory } from 'react-router-dom'
import { Error } from '../../../shared/Error'

export const CreateAppPage = () => {
  const history = useHistory()
  const app = { name: '', active: true }
  const [saving, setSaving] = useState(false)
  const [error, setError] = useState(undefined)

  const onSubmit = async (newApp: any) => {
    setSaving(true)

    try {
      const token = localStorage.getItem('carsweb-admin-token')

      const response = await fetch(`app`, {
        method: 'POST',
        body: JSON.stringify(newApp),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })

      setSaving(false)

      if (!response.ok) {
        // @ts-ignore
        throw new Error('Det oppstod en feil')
      }

      const app = await response.json()

      history.push(`/apps/${app.app_id}`)
    } catch (e) {
      setError(e)
      console.error(e)
    }
  }

  return (
    <React.Fragment>
      <PageHeader title="Ny app" parent={{ title: 'Apper', path: '/apps' }} />
      {error && <Error>{error}</Error>}
      {saving && <p>Oppretter app...</p>}
      {!saving && <AppForm app={app} onSubmit={onSubmit} disabled={saving} />}
    </React.Fragment>
  )
}
