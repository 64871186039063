import React, { useEffect, useState } from 'react'
import { Error } from '../../../shared/Error'
import { UserTable } from '../common/UserTable'
import { PageHeader } from '../../../shared/PageHeader'
import { Loading } from '../../../shared/Loading'
import { client } from '../../../../lib/client'

export const UserListPage = () => {
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>('idle')
  const [users, setUsers] = useState<any[]>([])

  useEffect(() => {
    setStatus('pending')

    client(`user`)
      .then(({ results }) => {
        setStatus('idle')
        setUsers(results)
      })
      .catch((error) => {
        console.log(error)
        setStatus('failure')
      })
  }, [])

  return (
    <div className="grid grid-cols-12 gap-x-md max-width mx-auto mt-md">
      <PageHeader title="Brukere" newPath="/users/create" />

      {status === 'pending' && (
        <div className="col-span-12 mt-md flex items-center justify-center">
          <Loading />
        </div>
      )}

      {status === 'failure' && <Error className="col-span-12">Det oppstod dessverre en feil</Error>}

      {users.length > 0 ? (
        <div className="col-span-12">
          <UserTable users={users} />
        </div>
      ) : null}
    </div>
  )
}
