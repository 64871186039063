import React, { FunctionComponent } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faEdit, faSave, faTrash, faBan } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'

const ActionContainer = styled.div`
  svg {
    margin: 8px 4px 0 4px;
    color: #0075ff;
  }
`

type Props = {
  category: any
  isEditing: boolean
  onAdd: () => void
  onEdit: () => void
  onSave: () => void
  onDelete: () => void
  onCancel: () => void
}

export const ActionToolbar: FunctionComponent<Props> = ({
  category,
  isEditing,
  onAdd,
  onEdit,
  onSave,
  onDelete,
  onCancel,
}) => {
  return (
    <ActionContainer>
      {!isEditing && category.id === 0 && (
        <FontAwesomeIcon icon={faPlus} title="Add" onClick={onAdd} />
      )}
      {!isEditing && category.id > 0 && (
        <FontAwesomeIcon icon={faEdit} title="Edit" onClick={onEdit} />
      )}
      {isEditing && (
        <>
          <FontAwesomeIcon icon={faSave} title="Save" onClick={onSave} />
          {category.id > 0 && <FontAwesomeIcon icon={faTrash} title="Delete" onClick={onDelete} />}
          <FontAwesomeIcon icon={faBan} title="Cancel" onClick={onCancel} />
        </>
      )}
    </ActionContainer>
  )
}
