import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Error } from '../../../shared/Error'
import { PageHeader } from '../../../shared/PageHeader'
import { ConsumerTable } from '../common/ConsumerTable'
import { Loading } from '../../../shared/Loading'
import { client } from '../../../../lib/client'

export const AppDetailPage = () => {
  let { id } = useParams<{ id: string }>()

  const [status, setStatus] = useState('idle')
  const [app, setApp] = useState<any>(undefined)
  const [consumers, setConsumers] = useState([])

  useEffect(() => {
    setStatus('pending')

    client(`app/${id}`)
      .then((response) => {
        setApp(response)
        return client(`app/${id}/consumers`)
      })
      .then((response) => {
        setStatus('idle')
        setConsumers(response)
      })
      .catch(() => setStatus('failure'))
  }, [id])

  if (!app) {
    return (
      <div className="flex items-center justify-center">
        <Loading />
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-x-md">
      <div className="flex items-end justify-between">
        <PageHeader title={app.name ?? ''} parent={{ title: 'Apper', path: '/apps' }} />

        <h4 className="text-lg">Brukere {consumers ? <span>({consumers.length})</span> : null}</h4>
      </div>

      {status === 'failure' ? <Error>Det oppstod dessverre en feil.</Error> : null}

      {status === 'pending' && (
        <div className="flex items-center justify-center">
          <Loading />
        </div>
      )}

      <ConsumerTable appName={app.name} consumers={consumers} />
    </div>
  )
}
