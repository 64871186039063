import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

const Button = styled.button`
  border: 2px solid var(--black);
  border-radius: 0.5rem;
  padding: 0 0.5rem;
  margin-right: 1rem;
`

type Props = {
  open: boolean
  toggle: () => void
}

export const HierarchyListButton: FunctionComponent<Props> = ({ open, toggle }) => {
  return (
    <Button onClick={toggle}>
      {open ? <FontAwesomeIcon icon={faCaretDown} /> : <FontAwesomeIcon icon={faCaretRight} />}
    </Button>
  )
}
