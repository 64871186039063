import React, { FormEvent, FunctionComponent, useContext, useReducer, useState } from 'react'
import { ToastContext } from '../../../../contexts/ToastContext'
import { TextField } from '../../../shared/Form'
import { Button } from '../../../shared/Buttons'
import { client } from '../../../../lib/client'

const reducer = (state: any, { field, value }: any) => {
  return {
    ...state,
    [field]: value,
  }
}

type Props = {
  wholesaler: any
}

export const WholesaleDetailForm: FunctionComponent<Props> = ({ wholesaler }) => {
  const [status, setStatus] = useState('idle')
  const { showToast } = useContext(ToastContext)
  const [state, dispatch] = useReducer(reducer, { ...wholesaler })

  const onChange = ({ name, value }: any) => {
    dispatch({ field: name, value })
  }

  const { name, wholesale_number } = state

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()
    setStatus('pending')

    client(`wholesale/${wholesaler.wholesaler_id}`, {
      method: 'PATCH',
      body: {
        name: state.name,
        wholesale_number: state.wholesale_number,
        active: state.active,
      },
    })
      .then(() => {
        setStatus('idle')
        showToast({ type: 'SUCCESS', message: `Lagret!` })
      })
      .catch(() => {
        setStatus('idle')
        showToast({ type: 'ERROR', message: `Det oppstod en feil` })
      })
  }

  return (
    <React.Fragment>
      <form id="wholesaleForm" onSubmit={onSubmit}>
        <div className="grid">
          <div className="w-1/2">
            <TextField
              id="name"
              label="Navn"
              name="name"
              value={name}
              required={true}
              onChange={(value) => onChange({ name: 'name', value })}
            />
          </div>
        </div>

        <div className="flex ">
          <div className="w-1/2">
            <TextField
              id="wholesale_number"
              label="Grossistnr."
              name="wholesale_number"
              value={wholesale_number}
              required={true}
              onChange={(value) => onChange({ name: 'wholesale_number', value })}
            />
          </div>
        </div>

        <div className="mt-md">
          <div className="w-1/3" />
          <div className="w-1/3">
            <Button disabled={status === 'pending'}>
              {status === 'pending' ? 'Lagrer...' : 'Lagre'}
            </Button>
          </div>
        </div>
      </form>
    </React.Fragment>
  )
}
