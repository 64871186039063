import { FunctionComponent, useContext, useState } from 'react'
import { JobForm, JobList } from '../common/job'
import { ToastContext } from '../../../../contexts/ToastContext'
import { client } from '../../../../lib/client'

type Props = {
  workshop: any
}
const initVals = {
  id: 0,
  workshop_id: 0,
  email: '',
  title: '',
  description: '',
}

export const JobPage: FunctionComponent<Props> = ({ workshop }) => {
  const { showToast } = useContext(ToastContext)
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [jobs, setJobs] = useState([])
  const { email, workshop_id } = workshop.workshop
  const [job, setJob] = useState({
    ...initVals,
    workshop_id: workshop_id,
    email: email,
  })
  const [view, setView] = useState('jobform')

  const fetchJobs = () => {
    setLoading(true)

    client(`jobs`, {
      method: 'GET',
    })
      .then((response) => {
        setJobs(response)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
        showToast({ type: 'ERROR', message: 'Det oppstod dessverre en feil' })
      })
  }

  const onSubmit = (values: any) => {
    setSaving(true)

    client(`jobs`, {
      method: 'POST',
      body: values,
    })
      .then(() => {
        setSaving(false)
        showToast({ type: 'SUCCESS', message: 'Lagret!' })
        setView('joblist')
        fetchJobs()
      })
      .catch(() => {
        setSaving(false)
        showToast({ type: 'ERROR', message: 'Det oppstod dessverre en feil' })
      })
  }

  const onDelete = (id: number) => {
    setLoading(true)

    client(`jobs/${id}`, {
      method: 'DELETE',
    })
      .then(() => {
        showToast({ type: 'SUCCESS', message: 'SLETTET!' })
        setLoading(false)
        setView('joblist')
        fetchJobs()
      })
      .catch(() => {
        setLoading(false)
        showToast({ type: 'ERROR', message: ' ' })
      })
  }

  const onEdit = (item: any) => {
    setJob(item)
    setView('jobform')
  }

  return (
    <>
      <div className="flex space-x-md mb-sm">
        <h2
          className={`text-sm mb-xs uppercase font-bold border-solid ${
            view === 'jobform' && 'border-2'
          } px-sm cursor-pointer`}
          onClick={() => {
            setJob({
              ...initVals,
              workshop_id: workshop_id,
              email: email,
            })
            setLoading(true)
            setView('jobform')
            setTimeout(() => setLoading(false), 100)
          }}
        >
          Stillinger
        </h2>
        <h2
          className={`text-sm mb-xs uppercase font-bold border-solid ${
            view === 'joblist' && 'border-2'
          } px-sm cursor-pointer`}
          onClick={() => {
            fetchJobs()
            setView('joblist')
          }}
        >
          Stillinger liste
        </h2>
      </div>
      {loading && <div>Loading...</div>}
      {!loading && view === 'jobform' && (
        <JobForm saving={saving} onSubmit={onSubmit} onDelete={onDelete} job={job} />
      )}
      {!loading && view === 'joblist' && <JobList onEdit={onEdit} jobs={jobs} />}
    </>
  )
}
