import styled from 'styled-components'

type ButtonProps = {
  fullWidth?: boolean
}

export const Button = styled.button`
  display: ${({ fullWidth }: ButtonProps) => (fullWidth ? 'block' : 'inline-block')}
  width: ${({ fullWidth }: ButtonProps) => (fullWidth ? '100%' : 'auto')}
  height: 2.5rem;
  color: white;
  font-size: inherit;
  border: none;
  border-radius: var(--border-radius);
  background: var(--primary-color);
  transition: all 0.25s ease-out;
  padding: 0.7rem 2rem;

  &:disabled {
    background: var(--light-grey);
  }
`

export const SecondaryButton = styled(Button)`
  color: #333333;
  border: 1px solid #333333;
  background: white;
`

export const DangerButton = styled(Button)`
  color: white;
  background: var(--error);
`
