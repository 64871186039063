import React, { useEffect, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { WholesaleDetailForm } from './WholesaleDetailForm'
import { Error } from '../../../shared/Error'
import { HandleApps } from '../../../shared/HandleApps'
import { AdItemOrders } from './AdItemOrders'
import { WorkshopTable } from '../../workshops/common/WorkshopTable'
import { sortByName } from '../../../../lib/helpers'
import { PageHeader } from '../../../shared/PageHeader'
import { Tab } from '../../../shared/Tabs'
import { Loading } from '../../../shared/Loading'
import { client } from '../../../../lib/client'

export const WholesalerDetailPage = () => {
  const history = useHistory()
  const location = useLocation()
  let { id } = useParams<{ id: string }>()
  const tabItems = ['Info', 'Verksteder', 'Apper', 'Ordre']
  const initialTab = location.search ? location.search.replace('?tab=', '') : tabItems[0]

  const [status, setStatus] = useState('idle')
  const [workshops, setWorkshops] = useState<any[]>([])
  const [wholesaler, setWholesaler] = useState<any>()
  const [tab, setTab] = useState(initialTab)

  useEffect(() => {
    setStatus('pending')

    client(`wholesale/${id}`)
      .then((result) => {
        setWholesaler(result)
        return client(`wholesale/${id}/workshops`)
      })
      .then((result) => {
        setStatus('idle')
        setWorkshops(result)
      })
      .catch((error) => {
        setStatus('failure')
      })
  }, [id])

  if (status === 'failure') {
    return <Error>Det oppstod dessverre en feil.</Error>
  }

  if (wholesaler) {
    return (
      <React.Fragment>
        <PageHeader
          title={wholesaler.name}
          parent={{ title: 'Grossister', path: '/wholesalers' }}
        />

        <div className="grid grid-cols-12 gap-x-md">
          <div className="col-span-3">
            <div key={`tab-${tab}`} aria-label="grossist tabber">
              {tabItems.map((item, i) => (
                <Tab
                  key={`tab-${i}`}
                  label={item}
                  active={item === tab}
                  onClick={(newValue: string) => {
                    history.push(`?tab=${newValue}`)
                    setTab(newValue)
                  }}
                />
              ))}
            </div>
          </div>
          <div className="col-span-9">
            {wholesaler && tab === 'Info' && <WholesaleDetailForm wholesaler={wholesaler} />}

            {tab === 'Verksteder' && workshops && (
              <div style={{ margin: '2rem 0' }}>
                {workshops.length > 0 ? (
                  <WorkshopTable workshops={workshops.sort(sortByName)} />
                ) : (
                  <p>Ingen verksteder tilknyttet denne grossisten.</p>
                )}
              </div>
            )}

            {tab === 'Apper' && <HandleApps type="wholesale" id={id} />}

            {tab === 'Ordre' && <AdItemOrders id={id} />}
          </div>
        </div>
      </React.Fragment>
    )
  }

  return (
    <div className="flex items-center justify-center">
      <Loading />
    </div>
  )
}
