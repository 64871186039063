import React from 'react'
import { NavLink } from 'react-router-dom'
import { Button } from './components/shared/Buttons'
import styled from 'styled-components'

const Container = styled.nav`
  margin: 0 auto;
  display: flex;
  max-width: 1227px;
  height: 4rem;
  align-items: center;
  justify-content: space-between;
`

const NavBarBrand = styled.h2`
  flex: 2;
  margin: 0 1.5rem 0 0;
  color: white;
`

const NavItem = styled(NavLink)`
  display: block;
  flex: 1;
  text-align: center;
  padding: 0.5rem 1rem;
  border-radius: var(--border-radius);

  &:link,
  &:visited {
    color: white;
    text-decoration: none;
  }

  &.active {
    background: rgba(255, 255, 255, 0.2);
  }
`

export const NavBar = () => {
  const logout = (e) => {
    e.preventDefault()
    localStorage.clear()
    window.location.href = '/'
  }

  return (
    <nav className="bg-primary">
      <Container>
        <NavBarBrand>Cars Web Admin</NavBarBrand>

        <NavItem to="/workshops">Verksted</NavItem>
        <NavItem to="/wholesalers">Grossist</NavItem>
        <NavItem to="/users">Brukere</NavItem>
        <NavItem to="/apps">Apper</NavItem>
        <NavItem to="/collection-pages">Samlesider</NavItem>
        <NavItem to="/web-orders">Timebestilling</NavItem>
        <NavItem to="/help">Hjelp</NavItem>

        <Button onClick={logout}>Logg ut</Button>
      </Container>
    </nav>
  )
}
