import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

type Props = {
  app: any
  children?: any
}

export const AppListItem: FunctionComponent<Props> = ({ app, children }) => {
  return (
    <tr>
      <td>
        <Link to={'/apps/' + app.app_id}>{app.name}</Link>
      </td>
      <td>{app.active ? 'Ja' : 'Nei'}</td>
      <td style={{ textAlign: 'right' }}>{children}</td>
    </tr>
  )
}
