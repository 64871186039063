import React, { FunctionComponent, useState } from 'react'
import { TextField } from './Form'

type Props = {
  data: any
  filterChanged: (values: any[]) => void
}

export const Filter: FunctionComponent<Props> = ({ data, filterChanged }) => {
  const [filter, setFilter] = useState('')

  const onChange = (value: string) => {
    setFilter(value)
    const filteredData = data.filter((d: any) => d.name.toLowerCase().includes(value.toLowerCase()))

    filterChanged(filteredData)
  }

  return <TextField label="Filtrér" name="filter" value={filter} onChange={onChange} />
}
