import React, { useCallback, useEffect, useState } from 'react'
import { client } from '../../../../lib/client'
import { useParams } from 'react-router-dom'
import { ExistingTireFiles } from './ExistingTireFiles'
import { FileUpload } from './FileUpload'
import { UpdateArticleGroups } from './UpdateArticleGroups'

export const UpdateTiresPage = () => {
  const { id } = useParams<{ id: string }>()
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>('idle')
  const [files, setFiles] = useState<S3Object[]>([])

  const fetchFiles = useCallback(() => {
    setStatus('pending')

    client(`wholesale/${id}/tires`)
      .then((response) => {
        setStatus('idle')
        setFiles(response)
      })
      .catch(() => {
        setStatus('failure')
      })
  }, [id])

  useEffect(() => {
    fetchFiles()
  }, [fetchFiles])

  return (
    <div className="mt-md flex flex-col gap-x-md">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl">Dekkfiler</h1>

        <FileUpload wholesalerId={id} onFileUploaded={fetchFiles} />
      </div>

      {status !== 'pending' && <ExistingTireFiles files={files} />}

      {files.length > 0 && <UpdateArticleGroups wholesalerId={id} />}
    </div>
  )
}
