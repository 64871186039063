import React, { FunctionComponent, useEffect, useState } from 'react'
import { AppListItem } from '../pages/apps/list/AppListItem'
import { AppList } from '../pages/apps/list/AppList'
import { sortByName } from '../../lib/helpers'
import { Error } from './Error'
import { Button } from './Buttons'
import { Loading } from './Loading'
import { client } from '../../lib/client'

const appIsActive = (app: any, activeApps: any[]) =>
  activeApps.findIndex((a) => app.app_id === a.app_id) !== -1

type Props = {
  type: string
  id: string
}

export const HandleApps: FunctionComponent<Props> = ({ type, id }) => {
  const [allApps, setAllApps] = useState<any[]>([])
  const [activeApps, setActiveApps] = useState<any[]>([])
  const [status, setStatus] = useState('idle')
  const [error, setError] = useState('')
  const [message, setMessage] = useState('')
  const [activeAppId, setActiveAppId] = useState<string | undefined>(undefined)

  useEffect(() => {
    setStatus('pending')

    client(`${type}/${id}/app`)
      .then((result) => {
        setStatus('idle')
        setActiveApps(result)
      })
      .catch((error) => {
        setStatus('failure')
        setError('Det oppstod dessverre en feil')
      })
  }, [id, type])

  useEffect(() => {
    client(`app`)
      .then(({ results }) => setAllApps(results))
      .catch(() => setError('Det oppstod dessverre en feil'))
  }, [])

  const removeApp = async (appId: string) => {
    setActiveAppId(appId)

    client(`${type}/${id}/app/${appId}`, {
      method: 'DELETE',
    })
      .then(() => {
        setActiveAppId(undefined)
        setActiveApps(activeApps.filter((a) => a.app_id !== appId))
      })
      .catch(() => {
        setActiveAppId(undefined)
        setError('Det oppstod dessverre en feil')
      })
  }

  const addApp = async (app: any) => {
    setActiveAppId(app.app_id)

    client(`${type}/${id}/app`, {
      method: 'POST',
      body: {
        app_id: app.app_id,
      },
    })
      .then(() => {
        setActiveAppId(undefined)
        setActiveApps([...activeApps, app])
      })
      .catch(() => {
        setActiveAppId(undefined)
        setError('Det oppstod dessverre en feil')
      })
  }

  const updateAdData = async () => {
    try {
      setStatus('pending')
      await client(`${type}/${id}/ad-items`, {
        method: 'PATCH',
      })
      setStatus('idle')
      setMessage('Varer oppdatert!')
      setTimeout(() => setMessage(''), 3000)
    } catch (e) {
      setStatus('failure')
      setMessage('Det oppstod dessverre en feil.')
      setTimeout(() => setMessage(''), 3000)
    }
  }

  if (status === 'pending') {
    return (
      <div className="flex items-center justify-center">
        <Loading />
      </div>
    )
  }

  return (
    <React.Fragment>
      {error && <Error>{error}</Error>}

      <div className="flex gap-x-md">
        <div className="w-1/2">
          <h2 className="text-lg mb-md">Aktive apper</h2>

          {activeApps ? (
            <AppList aria-label="Aktive">
              {activeApps.sort(sortByName).map((app) => {
                return (
                  <AppListItem key={app.app_id} app={app}>
                    {activeAppId === app.app_id ? (
                      <div className="flex items-center justify-center">
                        <Loading />
                      </div>
                    ) : (
                      <button className="btn" onClick={() => removeApp(app.app_id)}>
                        Fjern
                      </button>
                    )}
                  </AppListItem>
                )
              })}
            </AppList>
          ) : null}
        </div>

        <div className="w-1/2">
          <h2 className="text-lg mb-md">Tilgjengelige apper</h2>

          {activeApps && allApps ? (
            <AppList>
              {allApps
                .filter((app) => !appIsActive(app, activeApps))
                .map((app, i) => (
                  <AppListItem key={`allApp-${i + 1}`} app={app}>
                    {activeAppId === app.app_id ? (
                      <div className="flex items-center justify-center">
                        <Loading />
                      </div>
                    ) : (
                      <Button onClick={() => addApp(app)}>Legg til</Button>
                    )}
                  </AppListItem>
                ))}
            </AppList>
          ) : null}
        </div>
      </div>

      {type === 'wholesale' && (
        <React.Fragment>
          <div className="flex gap-x-md">
            <div className="w-1/2">
              <h3 className="text-lg mb-md mt-md">Oppdater data</h3>

              {message && <p>{message}</p>}

              <button className="btn" onClick={updateAdData} disabled={status === 'updating'}>
                Oppdater fra AutoData FTP
              </button>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
