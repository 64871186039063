import React, { FunctionComponent, useState } from 'react'
import { Dialog } from '../../../shared/modal/Dialog'
import styled from 'styled-components'
import { TextField } from '../../../shared/Form'

const CardContent = styled.div`
  max-height: 70vh;
  overflow: scroll;
`

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const ListItem = styled.li`
  padding: 1rem;
  transition: all 0.25s ease-out;

  &:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.05);
  }

  &:not(:last-child) {
    border-bottom: 1px solid #cccccc;
  }
`

type Props = {
  pages: any[]
  selected: any
  onChange: (pageId: string | null) => void
}

export const ParentPageSelector: FunctionComponent<Props> = ({ pages, selected, onChange }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [filter, setFilter] = useState('')

  return (
    <React.Fragment>
      <button className="btn" onClick={() => setIsOpen(!isOpen)}>
        {selected ? 'Bytt overordnet' : 'Velg overordnet'}
      </button>

      {isOpen && (
        <Dialog>
          <div className="card mx-auto p-md max-w-3xl">
            <div className="mb-md flex items-center justify-between">
              <h1 className="text-lg mb-0">Velg overordnet side</h1>
              <button className="btn" onClick={() => setIsOpen(false)}>
                Lukk
              </button>
            </div>

            <TextField
              label="Søk"
              value={filter ?? ''}
              autoFocus={true}
              name="search"
              onChange={(value) => setFilter(value)}
            />

            <CardContent>
              <List>
                <ListItem
                  onClick={() => {
                    setIsOpen(false)
                    onChange(null)
                  }}
                >
                  Ingen
                </ListItem>

                {pages
                  .filter((p) => p.name.toLowerCase().includes(filter.toLowerCase()))
                  .map((p, i) => (
                    <ListItem
                      key={`p-${i}`}
                      onClick={() => {
                        setIsOpen(false)
                        onChange(p.page_id)
                      }}
                    >
                      {p.name}
                    </ListItem>
                  ))}
              </List>
            </CardContent>
          </div>
        </Dialog>
      )}
    </React.Fragment>
  )
}
