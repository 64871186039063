import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const BreadcrumbsStyled = styled.div`
  margin-top: 2rem;

  a {
    &:link,
    &:visited {
      color: inherit;
    }
  }
`

type Props = {
  parent: { path: string; title: string }
  title: string
}

export const Breadcrumbs: FunctionComponent<Props> = ({ parent, title }) => {
  return (
    <BreadcrumbsStyled>
      <Link to={parent.path}>{parent.title}</Link> / {title}
    </BreadcrumbsStyled>
  )
}
