import React, { FunctionComponent, useEffect, useState } from 'react'
import { Error } from '../../../shared/Error'
import { Loading } from '../../../shared/Loading'
import { ConfirmDialog } from '../../../shared/modal/Confirm'
import styled from 'styled-components'
import { client } from '../../../../lib/client'

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const CenterFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

type Props = {
  dist: string
}

export const WorkshopHistory: FunctionComponent<Props> = ({ dist }) => {
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>('idle')
  const [history, setHistory] = useState<any[]>([])
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false)

  useEffect(() => {
    if (!dist) {
      return
    }

    setStatus('pending')

    client(`workshop/${dist}/history`)
      .then(({ results }) => {
        setHistory(results)
        setStatus('idle')
      })
      .catch((error) => {
        setStatus('failure')
      })
  }, [dist])

  if (!dist) {
    return <p>Ingen historikk registrert på dette verkstedet.</p>
  }

  const deleteHistory = () => {
    setShowConfirmDelete(false)
    setStatus('pending')

    client(`workshop/${dist}/history`, { method: 'DELETE' })
      .then(() => {
        setHistory([])
        setStatus('idle')
      })
      .catch((error) => {
        setStatus('failure')
      })
  }

  if (status === 'failure') {
    return <Error>Det oppstod dessverre en feil.</Error>
  }

  if (status === 'pending') {
    return (
      <CenterFlex>
        <Loading />
      </CenterFlex>
    )
  }

  return (
    <React.Fragment>
      <Header>
        <h2 className="text-lg mb-md">Historikk</h2>

        <button className="btn btn-danger" onClick={() => setShowConfirmDelete(true)}>
          Slett historikk
        </button>
      </Header>

      {history.length === 0 ? (
        <p>Ingen historikk registrert på dette verkstedet.</p>
      ) : (
        <table className="table">
          <thead>
            <th>DIST</th>
            <th>Dato</th>
            <th>Regnr.</th>
            <th>Kunde tlf.</th>
            <th>Verksted</th>
          </thead>
          <tbody>
            {history.map((h) => (
              <tr>
                <td>{h.dist}</td>
                <td>{new Date(h.invoice_date).toLocaleDateString()}</td>
                <td>{h.regno}</td>
                <td>{h.customer_phone}</td>
                <td>{h.workshop_name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {showConfirmDelete && (
        <ConfirmDialog
          title="Slette all historikk?"
          description="All historikk for dette verkstedet vil slettes og kan ikke gjenopprettes."
          confirmButtonText="Slett"
          cancelButtonText="Avbryt"
          onCancel={() => setShowConfirmDelete(false)}
          onConfirm={deleteHistory}
        />
      )}
    </React.Fragment>
  )
}
