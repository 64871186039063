import React, { useContext, useEffect, useState } from 'react'
import { PageHeader } from '../../../shared/PageHeader'
import { CollectionPagesHierarchy } from '../common/CollectionPagesHierarchy'
import { Loading } from '../../../shared/Loading'
import { Error } from '../../../shared/Error'
import { client } from '../../../../lib/client'
import { ToastContext } from '../../../../contexts/ToastContext'
import { CollectionPageWorkshops } from '../common/CollectionPageWorkshops'

export const CollectionPageList = () => {
  const { showToast } = useContext(ToastContext)
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>('idle')
  const [updatingPageId, setUpdatingPageId] = useState<string>('')
  const [pages, setPages] = useState<any[]>([])

  useEffect(() => {
    setStatus('pending')

    client(`collection-page/hierarchy`)
      .then((hierarchy) => {
        setPages(hierarchy)
        setStatus('idle')
      })
      .catch(() => {
        setStatus('failure')
      })
  }, [])

  const updatePage = (data: any) => {
    setUpdatingPageId(data.page_id)

    return client(`collection-page/${data.page_id}`, {
      method: 'PATCH',
      body: { name: data.name, parent_id: data.parent_id },
    })
      .then((updatedPage) => {
        setPages(pages.map((page) => (page.page_id === data.page_id ? updatedPage : page)))
        setUpdatingPageId('')
      })
      .catch(() => {
        setStatus('failure')
        setUpdatingPageId('')
      })
  }

  const deletePage = (data: any) => {
    setUpdatingPageId(data.page_id)

    return client(`collection-page/${data.page_id}`, {
      method: 'DELETE',
    })
      .then(() => {
        setPages(pages.filter((page) => page.page_id !== data.page_id))
        setUpdatingPageId('')
        showToast({ type: 'SUCCESS', message: 'Siden ble slettet' })
      })
      .catch(() => {
        setStatus('failure')
        setUpdatingPageId('')
        showToast({ type: 'ERROR', message: 'Det oppstod dessverre en feil' })
      })
  }

  const removeParentPage = (pageId: string) => {
    setUpdatingPageId(pageId)

    return client(`collection-page/${pageId}/parent`, {
      method: 'DELETE',
    })
      .then((updatedList) => {
        setPages(updatedList)
        setUpdatingPageId('')
        showToast({ type: 'SUCCESS', message: 'Siden ble endret' })
      })
      .catch(() => {
        setStatus('failure')
        setUpdatingPageId('')
        showToast({ type: 'ERROR', message: 'Det oppstod dessverre en feil' })
      })
  }

  if (status === 'pending') {
    return <Loading />
  }

  return (
    <div className="grid grid-cols-12 gap-x-md max-width mx-auto mt-md">
      <PageHeader title="Samlesider" newPath="/collection-pages/create" />

      {status === 'failure' && (
        <Error className="col-span-12">Det oppstod dessverre en feil.</Error>
      )}

      <div className="col-span-12">
        <CollectionPagesHierarchy
          removeParentPage={removeParentPage}
          updatingPageId={updatingPageId}
          onChange={updatePage}
          pages={pages}
          onDelete={deletePage}
        />
      </div>

      <div className="col-span-12">
        <CollectionPageWorkshops pages={pages} />
      </div>
    </div>
  )
}
