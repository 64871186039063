import firebase from 'firebase/app'
import 'firebase/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyDpI6vnJNMOxeT6UqOY37-jy66m9ajkzyY',
  authDomain: 'cars-bilxtra-staging.firebaseapp.com',
  databaseURL: 'https://cars-bilxtra-staging.firebaseio.com',
  projectId: 'cars-bilxtra-staging',
  storageBucket: 'cars-bilxtra-staging.appspot.com',
  messagingSenderId: '952694512418',
  appId: '1:952694512418:web:bc5250e67a9bafbe8ae37b',
}

firebase.initializeApp(firebaseConfig)

const storage = firebase.storage()

export { storage, firebase as default }
