import React, { FormEvent, useContext, useState } from 'react'
import { Error } from '../../../shared/Error'
import { useHistory } from 'react-router-dom'
import { PageHeader } from '../../../shared/PageHeader'
import { TextField } from '../../../shared/Form'
import { client } from '../../../../lib/client'
import { ToastContext } from '../../../../contexts/ToastContext'
import { CheckBox } from '../../../shared/form/CheckBox'

export const CreateCollectionPage = () => {
  const history = useHistory()
  const { showToast } = useContext(ToastContext)
  const [status, setStatus] = useState('idle')
  const [name, setName] = useState('')
  const [hidden, setHidden] = useState(false)

  const onCreatePage = (e: FormEvent) => {
    e.preventDefault()

    setStatus('pending')

    return client(`collection-page`, {
      method: 'POST',
      body: { name, hidden },
    })
      .then(() => {
        setStatus('idle')
        showToast({ type: 'SUCCESS', message: `${name} ble opprettet!` })
        history.push('/collection-pages')
      })
      .catch(() => setStatus('failure'))
  }

  const disableButton = status === 'pending' || Boolean(!name)

  return (
    <div className="flex flex-col gap-x-md mt-md">
      <PageHeader title="Samlesider" newPath="/collection-pages/create" />

      <div className="flex">
        <div className="w-1/2">
          <form onSubmit={onCreatePage}>
            <TextField
              id="name"
              label="Navn"
              name="name"
              value={name}
              onChange={(value) => setName(value)}
              autoFocus
            />

            <CheckBox label="Skjult" checked={hidden} onChange={setHidden} />

            {status === 'failure' && <Error>Det oppstod dessverre en feil</Error>}

            <div className="flex">
              <div className="w-1/3">
                <button
                  className="btn"
                  type="button"
                  onClick={() => history.push('/collection-pages')}
                  disabled={status === 'pending'}
                >
                  Avbryt
                </button>
              </div>
              <div className="w-1/3">
                <button className="btn btn-primary" type="submit" disabled={disableButton}>
                  Lag side
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
