import React, { createContext, FunctionComponent, useState } from 'react'
import Toast from '../components/pages/styled/toast'

type ToastType = { type: 'SUCCESS' | 'ERROR'; message: string }

export const ToastContext = createContext({
  showToast: (toast: ToastType) => {},
})

export const ToastProvider: FunctionComponent = ({ children }) => {
  const [toast, setToast] = useState<ToastType | undefined>(undefined)

  const showToast = (toast: ToastType) => {
    setToast(toast)

    setTimeout(() => {
      setToast(undefined)
    }, 5000)
  }

  return (
    <ToastContext.Provider
      value={{
        showToast,
      }}
    >
      {toast && <Toast type={toast.type} message={toast.message} />}
      {children}
    </ToastContext.Provider>
  )
}
