import React, { FunctionComponent } from 'react'
import { Dialog } from './Dialog'
import styled from 'styled-components'

export const Card = styled.div`
  border-radius: 4px;
  background: white;
`

const DialogCard = styled(Card)`
  position: relative;
  padding: var(--leading);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  top: 10rem;
  left: 50%;
  transform: translateX(-50%);
  max-width: 992px;
`

const DialogActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: var(--leading);
`

type Props = {
  title: string
  description?: string
  confirmButtonText?: string
  cancelButtonText?: string
  onClose?: () => void
  onConfirm: () => void
  onCancel: () => void
}

export const ConfirmDialog: FunctionComponent<Props> = ({
  title,
  description,
  confirmButtonText,
  cancelButtonText,
  onClose,
  onConfirm,
  onCancel,
}) => {
  return (
    <Dialog onClose={onClose}>
      <DialogCard>
        <h1 className="text-xl">{title}</h1>
        {description && <p className="my-md">{description}</p>}

        <DialogActions>
          <button className="btn" onClick={onCancel}>
            {cancelButtonText ?? 'Nei'}
          </button>
          <button className="btn btn-primary" onClick={onConfirm}>
            {confirmButtonText ?? 'Ja'}
          </button>
        </DialogActions>
      </DialogCard>
    </Dialog>
  )
}
