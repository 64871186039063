/* eslint-disable prettier/prettier */
const dev = {
    API_URL: 'http://localhost:5000/api/v3/',
}

const staging = {
    API_URL: 'https://cars-web-api-production.herokuapp.com/api/v3/',
}

const prod = {
    API_URL: 'https://cars-web-api-production.herokuapp.com/api/v3/',
}

export const config =
    process.env.REACT_APP_STAGE === 'prod'
        ? prod
        : process.env.REACT_APP_STAGE === 'dev'
            ? dev
            : staging
