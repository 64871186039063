import React, { FunctionComponent, useContext, useEffect, useState } from 'react'
import { Loading } from '../../../shared/Loading'
import { Error } from '../../../shared/Error'
import { client } from '../../../../lib/client'
import { ToastContext } from '../../../../contexts/ToastContext'

type Props = {
  workshopId: string
}

export const WorkshopCollectionPages: FunctionComponent<Props> = ({ workshopId }) => {
  const { showToast } = useContext(ToastContext)
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>('idle')
  const [pages, setPages] = useState<any[]>()
  const [allPages, setAllPages] = useState<any[]>()

  useEffect(() => {
    client(`workshop/${workshopId}/collection-page`)
      .then((response) => {
        setPages(response)
      })
      .catch(() => setStatus('failure'))
  }, [workshopId])

  useEffect(() => {
    client(`collection-page`)
      .then((response) => {
        setAllPages(response)
      })
      .catch(() => setStatus('failure'))
  }, [])

  const removeWorkshopFromPage = (pageId: string) => {
    setStatus('pending')

    client(`collection-page/${pageId}/workshop`, {
      method: 'DELETE',
      body: { workshop_id: workshopId },
    })
      .then(() => {
        showToast({ type: 'SUCCESS', message: 'Siden ble fjernet' })
        setPages(pages!.filter((p) => p.page_id !== pageId))
        setStatus('idle')
      })
      .catch((error) => setStatus('failure'))
  }

  const addWorkshopToPage = (pageId: string) => {
    setStatus('pending')

    client(`collection-page/${pageId}/workshop`, {
      method: 'POST',
      body: { workshop_id: workshopId },
    })
      .then(() => client(`workshop/${workshopId}/collection-page`))
      .then((response) => {
        setPages(response)
        setStatus('idle')
        showToast({ type: 'SUCCESS', message: 'Siden ble lagt til' })
      })
      .catch((error) => setStatus('failure'))
  }

  return (
    <React.Fragment>
      <h2 className="text-lg mb-md">Samlesider</h2>

      {status === 'failure' && <Error>Det oppstod dessverre en feil.</Error>}

      <div className="flex gap-x-md">
        <div className="w-1/2">
          <h3 className="text-md mb-md">Tilknyttet</h3>

          {pages ? (
            <table className="table" aria-label="Tilknyttede sider">
              <tbody>
                {pages.map((page, i) => (
                  <tr key={`p-${i}`}>
                    <td>{page.page_name}</td>
                    <td className="text-right">
                      <button
                        className="btn btn-danger"
                        disabled={status === 'pending'}
                        onClick={() => removeWorkshopFromPage(page.page_id)}
                      >
                        Fjern
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <Loading />
          )}
        </div>

        <div className="w-1/2">
          <h3 className="text-md mb-md">Alle sider</h3>

          {allPages && pages ? (
            <table className="table" aria-label="Alle sider">
              <tbody>
                {allPages
                  .filter((page) => {
                    const index = pages.findIndex((p) => page.page_id === p.page_id)
                    return index === -1
                  })
                  .map((page, i) => (
                    <tr key={`p-${i}`}>
                      <td>{page.name}</td>
                      <td className="text-right">
                        <button
                          className="btn"
                          disabled={status === 'pending'}
                          onClick={() => addWorkshopToPage(page.page_id)}
                        >
                          Legg til
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          ) : (
            <Loading />
          )}
        </div>
      </div>
    </React.Fragment>
  )
}
