import React, { FunctionComponent, useContext, useEffect, useState } from 'react'
import { Error } from '../../../shared/Error'
import { ToastContext } from '../../../../contexts/ToastContext'
import { CheckBox } from '../../../shared/form/CheckBox'
import { client } from '../../../../lib/client'

const isChecked = (settings: any[], setting: any) => {
  const index = settings.findIndex((ws) => ws.app_setting_id === setting.app_setting_id)
  return index !== -1 ? settings[index].value === 'true' : false
}

type Props = {
  id: string
}

export const WorkshopAppSettings: FunctionComponent<Props> = ({ id }) => {
  const { showToast } = useContext(ToastContext)
  const [status, setStatus] = useState('idle')
  const [workshopSettings, setWorkshopSettings] = useState<any[] | undefined>(undefined)
  const [allSettings, setAllSettings] = useState<any[] | undefined>(undefined)

  useEffect(() => {
    setStatus('pending')

    client(`workshop/${id}/app/setting`)
      .then((result) => {
        setWorkshopSettings(result)
        return client(`app/${1}/setting`)
      })
      .then((result) => {
        setAllSettings(result)
        setStatus('resolved')
      })
      .catch((error) => {
        setStatus('failure')
        console.log(error)
      })
  }, [id])

  const updateSetting = async (setting: any, value: any) => {
    try {
      setStatus('pending')
      const result = await client(`workshop/${id}/app/setting`, {
        method: 'PATCH',
        body: { value: value.toString(), app_setting_id: setting.app_setting_id },
      })

      setWorkshopSettings(result)
      setStatus('idle')
      showToast({ type: 'SUCCESS', message: 'Lagret!' })
    } catch (e) {
      showToast({ type: 'ERROR', message: 'Det oppstod dessverre en feil' })
      setStatus('rejected')
    }
  }

  if (status === 'failure') {
    return <Error>Det oppstod dessverre en feil</Error>
  }

  if (allSettings && workshopSettings) {
    return (
      <React.Fragment>
        <h2 className="text-lg mb-md">Innstillinger</h2>
        {allSettings.map((setting, i) => {
          const checked = isChecked(workshopSettings, setting)

          return (
            <CheckBox
              label={`${setting.key} (${setting.name})`}
              key={`chbx-${i}`}
              checked={checked}
              onChange={(checked) => updateSetting(setting, checked)}
              disabled={status === 'pending'}
            />
          )
        })}
      </React.Fragment>
    )
  }

  return <p>Laster innstillinger...</p>
}
