import React, { FormEvent, FunctionComponent, useEffect, useState } from 'react'
import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { Option, Select, TextField } from '../../../shared/Form'
import { client } from '../../../../lib/client'

const createSlug = (name: string) => name.replace(/\s+/g, '-').toLowerCase()

type Props = {
  initialWorkshop: any
  onSubmit: (state: any) => void
  saving: boolean
  cancel: () => void
}

export const WorkshopFormV2: FunctionComponent<Props> = ({
  initialWorkshop,
  onSubmit,
  saving,
  cancel,
}) => {
  const [workshop, setWorkshop] = useState<any>(initialWorkshop)
  const [wholesalers, setWholesalers] = useState<any[]>()
  const [collectionPages, setCollectionPage] = useState<any[]>()

  useEffect(() => {
    client(`/wholesale`)
      .then((data) => {
        setWholesalers(data)
      })
      .catch((error) => console.log(error))
  }, [])

  useEffect(() => {
    client(`/collection-page`)
      .then((data) => {
        setCollectionPage(data)
      })
      .catch((error) => console.log(error))
  }, [])

  const handleChange = ({ name, value }: any) => {
    if (name === 'name') {
      setWorkshop({ ...workshop, slug: createSlug(value) })
    }

    setWorkshop({ ...workshop, [name]: value })
  }

  const _onSubmit = (e: FormEvent) => {
    e.preventDefault()
    onSubmit(workshop)
  }

  return (
    <form id="workshopForm">
      <h2 className="text-sm mb-xs uppercase font-bold">Info</h2>

      <div className="flex space-x-md mb-lg">
        <div className="w-1/3">
          <TextField
            id="navn"
            label="Navn"
            name="name"
            value={workshop.name || ''}
            required
            onChange={(value) => handleChange({ name: 'name', value })}
          />
        </div>

        <div className="w-1/3">
          <div>
            <TextField
              id="slug"
              label="Slug"
              name="slug"
              value={workshop.slug || ''}
              required
              onChange={(value) => handleChange({ name: 'slug', value })}
            />
          </div>
          <small>Dette blir verkstedets nettside eks: /verksted/{workshop.slug}</small>
        </div>

        <div className="w-1/3">
          <TextField
            id="dist"
            label="Distkode"
            name="dist"
            value={workshop.dist || ''}
            onChange={(value) => handleChange({ name: 'dist', value })}
          />
        </div>
      </div>

      <h2 className="text-sm mb-xs uppercase font-bold">Grossist</h2>
      <div className="flex space-x-md mb-lg">
        <div className="w-1/3">
          <Select
            id="wholesaler"
            label="Grossist"
            name="wholesaler"
            value={workshop.wholesaler_id || ''}
            key="name"
            onChange={(value) => handleChange({ name: 'wholesaler_id', value })}
          >
            {wholesalers
              ? wholesalers.map((wholesaler: any) => (
                  <Option key={`ws-${wholesaler.wholesaler_id}`} value={wholesaler.wholesaler_id}>
                    {wholesaler.name}
                  </Option>
                ))
              : null}
          </Select>
        </div>

        <div className="w-1/3">
          <TextField
            id="workshop_number"
            label="Verkstednummer"
            name="workshop_number"
            value={workshop.workshop_number || ''}
            onChange={(value) => handleChange({ name: 'workshop_number', value })}
          />
        </div>

        <div className="w-1/3">
          <TextField
            id="customer_number"
            label="Kundenummer"
            value={workshop.customer_number || ''}
            name="customerNumber"
            onChange={(value) => handleChange({ name: 'customer_number', value })}
          />
        </div>
      </div>

      <h2 className="text-sm mb-xs uppercase font-bold">Kontakt</h2>
      <div className="flex space-x-md mb-lg">
        <div className="w-1/3">
          <TextField
            id="email"
            label="E-post"
            name="email"
            value={workshop.email || ''}
            onChange={(value) => handleChange({ name: 'email', value })}
          />
        </div>

        <div className="w-1/3">
          <TextField
            id="phone"
            label="Telefon"
            name="phone"
            value={workshop.phone || ''}
            onChange={(value) => handleChange({ name: 'phone', value })}
          />
        </div>
      </div>

      <h2 className="text-sm mb-xs uppercase font-bold">Adresse</h2>
      <div className="flex space-x-md mb-lg">
        <div className="w-1/3">
          <TextField
            id="street"
            label="Gate"
            name="street"
            value={workshop.street || ''}
            onChange={(value) => handleChange({ name: 'street', value })}
          />
        </div>

        <div className="w-1/3">
          <TextField
            id="zip"
            label="Postnr."
            name="zip"
            value={workshop.zip || ''}
            onChange={(value) => handleChange({ name: 'zip', value })}
          />
        </div>

        <div className="w-1/3">
          <TextField
            id="city"
            label="Poststed"
            name="city"
            value={workshop.city || ''}
            onChange={(value) => handleChange({ name: 'city', value })}
          />
        </div>

        <div className="w-1/3">
          <TextField
            id="county"
            label="Fylke"
            name="country"
            value={workshop.county || ''}
            onChange={(value) => handleChange({ name: 'county', value })}
          />
        </div>
      </div>

      <h2 className="text-sm mb-xs uppercase font-bold">Kart</h2>
      <div className="flex space-x-md mb-lg">
        <div className="w-1/3">
          <TextField
            id="lat"
            label="Breddegrad"
            name="lat"
            value={workshop.lat || ''}
            onChange={(value) => handleChange({ name: 'lat', value })}
          />
        </div>

        <div className="w-1/3">
          <TextField
            id="long"
            label="Lengdegrad"
            name="long"
            value={workshop.long || ''}
            onChange={(value) => handleChange({ name: 'long', value })}
          />
        </div>

        <div className="w-1/3">
          <Select
            id="affiliation"
            label="Tilhørighet"
            name="affiliation"
            value={workshop.affiliation || null}
            key="name"
            onChange={(value) => handleChange({ name: 'affiliation', value })}
          >
            {collectionPages
              ? collectionPages.map((page: any) => (
                  <Option key={`p-${page.page_id}`} value={page.page_id}>
                    {page.name}
                  </Option>
                ))
              : null}
          </Select>
        </div>

        <div className="w-1/3" />
      </div>

      <h2 className="text-sm mb-xs uppercase font-bold">SoME</h2>
      <div className="flex space-x-md mb-lg">
        <div className="w-1/3">
          <TextField
            id="facebook"
            label="Facebook"
            name="facebook"
            value={workshop.facebook || ''}
            onChange={(value) => handleChange({ name: 'facebook', value })}
          />
        </div>

        <div className="w-1/3">
          <TextField
            id="homepage"
            label="Hjemmeside"
            name="homepage"
            value={workshop.homepage || ''}
            onChange={(value) => handleChange({ name: 'homepage', value })}
          />
        </div>

        <div className="w-1/3">
          <TextField
            id="instagram_url"
            label="Instagram"
            name="instagram_url"
            value={workshop.instagram_url || ''}
            onChange={(value) => handleChange({ name: 'instagram_url', value })}
          />
        </div>

        <div className="w-1/6" />
      </div>

      <h2 className="text-sm mb-xs uppercase font-bold">Om</h2>
      <div className="mb-lg">
        <CKEditor
          editor={ClassicEditor}
          data={workshop.free_text || ''}
          onChange={(event: any, editor: any) => {
            const data = editor.getData()
            handleChange({ name: 'free_text', value: data })
          }}
        />
      </div>

      <div className="flex space-x-md my-md justify-end">
        <div>
          <button
            className="btn btn-danger"
            onClick={(e) => {
              e.preventDefault()
              cancel()
            }}
            disabled={saving}
          >
            Avbryt
          </button>
        </div>
        <div>
          <button className="btn btn-primary" onClick={_onSubmit} disabled={saving}>
            {saving ? 'Lagrer...' : 'Lagre'}
          </button>
        </div>
      </div>
    </form>
  )
}
