import React, { FunctionComponent, useEffect, useState } from 'react'
import { Loading } from '../../../shared/Loading'
import { client } from '../../../../lib/client'
import { Error } from '../../../shared/Error'

type Props = {
  pages: any[]
}

export const CollectionPageWorkshops: FunctionComponent<Props> = ({ pages }) => {
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>('idle')
  const [workshops, setWorkshops] = useState<any[]>([])

  useEffect(() => {
    if (pages.length === 0) {
      return
    }

    setStatus('pending')

    client(`workshop?appId=1`)
      .then((data) => {
        setWorkshops(data)
        setStatus('idle')
      })
      .catch(() => {
        setStatus('failure')
      })
  }, [pages])

  return (
    <React.Fragment>
      <h2 className="heading-lg mt-md">Tilknyttede verksteder</h2>

      {status === 'pending' ? (
        <Loading />
      ) : status === 'failure' ? (
        <Error>Det oppstod dessverre en feil</Error>
      ) : (
        <table className="table">
          <tbody>
            {workshops.map((workshop, i) => {
              const collectionPages = workshop.collection_pages.map(
                (parentId: string) => pages.find((cp) => cp.page_id === parentId)?.name
              )

              return (
                <tr key={`cpw-${i}`}>
                  <td>{workshop.name}</td>
                  <td>{collectionPages.join(', ')}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      )}
    </React.Fragment>
  )
}
