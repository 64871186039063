import { FunctionComponent, useState } from 'react'
import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { TextField } from '../../../../shared/Form'
import useForm from '../../../../../hooks/useForm'

import { ConfirmDialog } from '../../../../shared/modal/Confirm'

type Props = {
  job: any
  onSubmit: (state: any) => void
  onDelete: (id: number) => void
  saving: boolean
}

export const JobForm: FunctionComponent<Props> = ({ job, onSubmit, onDelete, saving }) => {
  const [confirm, setConfirmDelete] = useState(false)

  const formSubmit = () => {
    onSubmit(values)
  }

  const { validateField, values, errors, handleSubmit }: any = useForm(job, formSubmit)

  const handleChange = ({ name, value }: any) => {
    validateField(name, value)
  }

  const handleDelete = (e: any) => {
    e.preventDefault()
    setConfirmDelete(true)
  }

  return (
    <>
      {confirm && (
        <ConfirmDialog
          title={`Slette?`}
          description={`Vil du slette denne?`}
          confirmButtonText="Slett"
          cancelButtonText="Avbryt"
          onCancel={() => setConfirmDelete(false)}
          onConfirm={() => {
            setConfirmDelete(false)
            onDelete(job?.id)
          }}
        />
      )}
      <form id="workshopForm">
        <div className="flex flex-col space-x-0 mb-sm mt-0 w-1/3">
          <TextField
            id="email"
            label="E-post"
            name="email"
            value={values.email || ''}
            onChange={(value) => handleChange({ name: 'email', value })}
          />
          {errors.email && (
            <span style={{ color: 'red', fontSize: '0.8rem' }} className="-mt-sm text-gray-200">
              {errors.email}
            </span>
          )}
        </div>
        <div className="flex flex-col space-x-0 mb-sm mt-0 w-1/2">
          <TextField
            id="title"
            label="Tittel"
            name="title"
            value={values.title || ''}
            onChange={(value) => handleChange({ name: 'title', value })}
          />
          {errors.title && (
            <span style={{ color: 'red', fontSize: '0.8rem' }} className="-mt-sm text-gray-200">
              {errors.title}
            </span>
          )}
        </div>

        <label className="mb-xs text-gray">Stillingsannonse tekst</label>
        <div className="mb-lg">
          <CKEditor
            id="description"
            name="description"
            editor={ClassicEditor}
            data={values.description || ''}
            onChange={(event: any, editor: any) => {
              const data = editor.getData()
              handleChange({ name: 'description', value: data })
            }}
            onInit={(editor: any) => {
              editor.editing.view.change((writer: any) => {
                writer.setStyle('height', '250px', editor.editing.view.document.getRoot())
              })
            }}
          />
          {errors.description && (
            <span style={{ color: 'red', fontSize: '0.8rem' }} className="-mt-sm text-gray-200">
              {errors.description}
            </span>
          )}
        </div>

        <div className="flex space-x-md my-md justify-end">
          <div>
            {!job?.id ? (
              <button className="btn btn-primary" onClick={handleSubmit} disabled={saving}>
                {saving ? 'Ny stilling...' : 'Ny stilling'}
              </button>
            ) : (
              <button className="btn btn-danger" onClick={handleDelete} disabled={saving}>
                {saving ? 'Slette...' : 'Slette'}
              </button>
            )}
          </div>
        </div>
      </form>
    </>
  )
}
