import React, { FunctionComponent } from 'react'
import { useHistory } from 'react-router-dom'

type Props = {
  wholesaler: any
}

export const WholesalerListItem: FunctionComponent<Props> = ({ wholesaler }) => {
  const history = useHistory()

  return (
    <tr onClick={() => history.push(`/wholesalers/${wholesaler.wholesaler_id}`)}>
      <td>{wholesaler.wholesaler_id}</td>
      <td>{wholesaler.wholesale_number ? wholesaler.wholesale_number : '-'}</td>
      <td>{wholesaler.name}</td>
      <td>{wholesaler.active ? 'Ja' : 'Nei'}</td>
    </tr>
  )
}
