import React, { FunctionComponent } from 'react'

type Props = {
  label: string
  active: boolean
  onClick: (label: string) => void
}

export const Tab: FunctionComponent<Props> = ({ label, active, onClick }) => {
  return (
    <button role="tab" className={active ? 'tab active' : 'tab'} onClick={() => onClick(label)}>
      {label}
    </button>
  )
}
