import { UploadImage } from '../../../shared/UploadImage'
import { Image } from '../../../shared/Image'
import React, { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react'
import { Error } from '../../../shared/Error'
import { ToastContext } from '../../../../contexts/ToastContext'
import { client } from '../../../../lib/client'
import { ConfirmDialog } from '../../../shared/modal/Confirm'

type Props = {
  workshopId: string
}

export const Images: FunctionComponent<Props> = ({ workshopId }) => {
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>('idle')
  const [images, setImages] = useState<any[] | undefined>(undefined)
  const [imageToDelete, setImageToDelete] = useState<string>('')
  const { showToast } = useContext(ToastContext)

  const loadWorkshopImages = useCallback(() => {
    setStatus('pending')

    client(`workshop/${workshopId}/images`)
      .then((response) => {
        setStatus('idle')
        setImages(response)
      })
      .catch((error) => {
        setStatus('failure')
      })
  }, [workshopId])

  const deleteImage = () => {
    const imageId = imageToDelete
    setImageToDelete('')
    setStatus('pending')

    client(`workshop/${workshopId}/images/${imageId}`, {
      method: 'DELETE',
    })
      .then(() => {
        showToast({ type: 'SUCCESS', message: 'Bildet ble slettet!' })
        return loadWorkshopImages()
      })
      .catch(() => {
        setStatus('idle')
        showToast({ type: 'ERROR', message: 'Det oppstod en feil under sletting' })
      })
  }

  useEffect(() => {
    client(`workshop/${workshopId}/images`).then((response) => setImages(response))
  }, [workshopId])

  return (
    <div className="flex gap-x-md">
      <div className="w-1/2">
        <h2 className="text-lg mb-md">Last opp bilde</h2>
        <UploadImage
          uploadUrl={`workshop/${workshopId}/images`}
          onUploadCompleted={loadWorkshopImages}
        />
      </div>

      <div className="w-1/2">
        <h2 className="text-lg mb-md">Bilder</h2>

        {status === 'failure' && <Error>Det oppstod dessverre en feil.</Error>}

        {images &&
          images.length > 0 &&
          images.map((image, i) => (
            <Image image={image} key={`image-${i}`}>
              <button
                className="btn btn-danger w-full"
                disabled={status === 'pending'}
                onClick={async (e) => {
                  e.preventDefault()
                  setImageToDelete(image.image_id)
                }}
              >
                Slett
              </button>
            </Image>
          ))}

        {imageToDelete && (
          <ConfirmDialog
            title="Slette bildet?"
            onConfirm={deleteImage}
            onCancel={() => setImageToDelete('')}
          />
        )}
      </div>
    </div>
  )
}
