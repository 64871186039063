import React, { FormEvent, FunctionComponent, useReducer } from 'react'
import { Button } from '../../../shared/Buttons'
import { TextField } from '../../../shared/Form'
import { CheckBox } from '../../../shared/form/CheckBox'

const reducer = (state: any, { field, value }: any) => {
  return {
    ...state,
    [field]: value,
  }
}

type Props = {
  app: any
  onSubmit: ({ name: string, active: boolean }: any) => void
  disabled: boolean
}

export const AppForm: FunctionComponent<Props> = ({ app, onSubmit, disabled }) => {
  const [state, dispatch] = useReducer(reducer, { ...app })

  const onChange = (e: any) => {
    const { name, value } = e.target

    if (name === 'active') {
      dispatch({ field: name, value: e.target.checked })
    } else {
      dispatch({ field: name, value: value })
    }
  }

  const { name, active } = state

  const _onSubmit = (e: FormEvent) => {
    e.preventDefault()
    onSubmit({ name, active })
  }

  return (
    <form id="appForm" onSubmit={_onSubmit}>
      <div className="flex">
        <div className="w-1/2">
          <TextField id="navn" label="Navn" name="name" value={name} onChange={onChange} />

          <div className="mt-md">
            <CheckBox label="Aktiv" checked={active} onChange={onChange} />
          </div>

          <div className="w-1/2">
            <Button disabled={disabled || !name}>{disabled ? 'Lagrer...' : 'Lagre'}</Button>
          </div>
        </div>
      </div>
    </form>
  )
}
