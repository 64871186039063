import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

const FormGroup = styled.div`
  width: 100%;

  &:not(:last-child) {
    margin-bottom: var(--spacing-md);
  }

  label {
    display: block;
  }

  input {
    display: block;
    width: 100%;
    font-size: inherit;
    transition: border-color 0.25s ease-out;

    &:hover {
      border-color: #777777;
    }

    &:focus {
      border-color: var(--primary-color);
    }
  }
`

type TextFieldProps = {
  id?: string
  label: string
  type?: string
  name: string
  value: string
  onChange: (newValue: string) => void
  required?: boolean
  autoFocus?: boolean
  helpText?: string
}

type TextAreaProps = {
  id?: string
  label: string
  name: string
  value: string
  onChange: (newValue: string) => void
  required?: boolean
  autoFocus?: boolean
  helpText?: string
  rows?: number
}

export const TextField: FunctionComponent<TextFieldProps> = ({
  label,
  type,
  name,
  value,
  onChange,
  autoFocus,
  required,
  helpText,
}) => {
  return (
    <FormGroup>
      <label className="text-gray" htmlFor={label}>
        {label}
      </label>
      <input
        id={label}
        className="border border-gray-light rounded p-xs text-md"
        style={{ height: '3rem' }}
        type={type ?? 'text'}
        name={name}
        value={value}
        autoFocus={autoFocus}
        onChange={({ target: { value } }) => onChange(value)}
        required={required}
      />
      {helpText && <small>{helpText}</small>}
    </FormGroup>
  )
}

export const SmallTextField: FunctionComponent<TextFieldProps> = ({
  label,
  type,
  name,
  value,
  onChange,
  autoFocus,
  required,
  helpText,
}) => {
  return (
    <FormGroup>
      <label className="text-gray" htmlFor={label}>
        {label}
      </label>
      <input
        id={label}
        className="border border-gray-light rounded p-xs text-md"
        style={{ height: '2rem' }}
        type={type ?? 'text'}
        name={name}
        value={value}
        autoFocus={autoFocus}
        onChange={({ target: { value } }) => onChange(value)}
        required={required}
      />
      {helpText && <small>{helpText}</small>}
    </FormGroup>
  )
}

const SelectStyled = styled.select`
  width: 100%;
  padding: var(--spacing-sm);
  font-size: inherit;
  border: 1px solid #cccccc;
  border-radius: var(--border-radius);
  height: 3rem;

  &:hover {
    border-color: #777777;
  }

  &:focus {
    border-color: var(--primary-color);
  }
`
export const Option = styled.option``

type SelectProps = {
  id: string
  label: string
  name: string
  value: string
  onChange: (newValue: any) => void
}

export const Select: FunctionComponent<SelectProps> = ({
  id,
  label,
  name,
  value,
  onChange,
  children,
}) => {
  return (
    <FormGroup>
      <label className="text-gray" htmlFor={id}>
        {label}
      </label>
      <SelectStyled
        id={id}
        name={name}
        value={value}
        onChange={({ target: { value } }) => onChange(value)}
      >
        <Option value="">Velg</Option>
        {children}
      </SelectStyled>
    </FormGroup>
  )
}

export const TextArea: FunctionComponent<TextAreaProps> = ({
  label,
  name,
  value,
  onChange,
  autoFocus,
  required,
  helpText,
  rows,
}) => {
  return (
    <FormGroup>
      <label className="text-gray" htmlFor={label}>
        {label}
      </label>
      <textarea
        id={label}
        className="border border-gray-light rounded p-xs"
        style={{ width: '100%', lineHeight: '20px' }}
        rows={rows ?? 4}
        name={name}
        value={value}
        autoFocus={autoFocus}
        onChange={({ target: { value } }) => onChange(value)}
        required={required}
      />
      {helpText && <small>{helpText}</small>}
    </FormGroup>
  )
}
