import { TextField } from '../../../shared/Form'
import { Error } from '../../../shared/Error'
import React, { FunctionComponent, useState } from 'react'
import { client } from '../../../../lib/client'
import { Loading } from '../../../shared/Loading'

type Props = {
  wholesalerId: string
}

export const UpdateArticleGroups: FunctionComponent<Props> = ({ wholesalerId }) => {
  const [status, setStatus] = useState<'idle' | 'pending' | 'success' | 'failure'>('idle')
  const [articleGroups, setArticleGroups] = useState('')
  const [leftOverStockGroups, setLeftOverStockGroups] = useState('')

  const triggerTireUpdate = (e: any) => {
    e.preventDefault()

    setStatus('pending')

    client(`wholesale/${wholesalerId}/tires`, {
      method: 'PATCH',
      body: {
        article_groups: articleGroups.split(',').filter((group) => group !== ''),
        left_over_stock_groups: leftOverStockGroups.split(',').filter((group) => group !== ''),
      },
    })
      .then(() => {
        setStatus('success')

        setTimeout(() => {
          setStatus('idle')
        }, 5000)
      })
      .catch(() => setStatus('failure'))
  }

  return (
    <React.Fragment>
      <h2 className="text-lg">Oppdater artikkelgrupper</h2>

      {status === 'success' ? (
        <React.Fragment>
          <h3 className="text-md text-success">Oppdateringen har startet!</h3>
          <p>Det vil ta noen minutter før Dekksøk appen er oppdatert.</p>
        </React.Fragment>
      ) : (
        <form className="flex flex-col gap-x-md" onSubmit={triggerTireUpdate}>
          <p>
            For å oppdatere Dekksøkappen må du legge til hvilke artikkelgrupper som skal brukes.
          </p>

          <TextField
            id="articleGroups"
            label="Artikkelgrupper"
            name="articleGroups"
            value={articleGroups}
            onChange={(value) => setArticleGroups(value.replace(/\s/g, ''))}
            helpText="Komma separert liste. Eks. 5200,52001,53400"
            required
          />

          <TextField
            id="leftOverStockGroups"
            label="Restlager artikkelgrupper"
            name="leftOverStockGroups"
            value={leftOverStockGroups}
            onChange={(value) => setLeftOverStockGroups(value.replace(/\s/g, ''))}
            helpText="Komma separert liste. Eks. 5200,52001,53400"
          />

          {status === 'failure' && <Error>Det oppstod dessverre en feil</Error>}

          <button
            type="submit"
            className="btn self-end"
            disabled={!articleGroups || status === 'pending'}
          >
            {status === 'pending' ? <Loading /> : 'Oppdater'}
          </button>
        </form>
      )}
    </React.Fragment>
  )
}
