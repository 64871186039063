import React, { FunctionComponent, FormEvent, useState, useEffect } from 'react'

import Select from 'react-select'
import { SmallTextField, TextArea } from '../../../shared/Form'
import { CheckBox } from '../../../shared/form/CheckBox'
import { ActionToolbar } from '../common/ActionToolbar'
import styled from 'styled-components'

import { Dialog } from '../../../shared/modal/Dialog'
import { ConfirmDialog } from '../../../shared/modal/Confirm'

const CardContent = styled.div`
  min-width: 80vh;
  min-height: 43vh;
  overflow: hidden;
  background: #fff;
  padding: 2vh 6vh;
  border-radius: 10px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  input#Duration {
    text-align: right;
  }
`

type Props = {
  workshop: any
  categories: any
  service: any
  saving: boolean
  onChange: (name: string, value: any) => void
  onSubmitService: (type: string, serviceId: string, service: any) => void
  onSubmitCategory: (type: string, catId: string, category: any) => void
  onCancel: () => void
}

type CategoryProps = {
  id: number
  workshop_id: number
  name: string
}
const defaultCategory: CategoryProps = {
  id: 0,
  workshop_id: 0,
  name: '',
}
export const ServicesForm: FunctionComponent<Props> = ({
  workshop,
  categories,
  service,
  saving,
  onChange,
  onSubmitService,
  onSubmitCategory,
  onCancel,
}) => {
  const [confirm, setConfirmDelete] = useState({ confirm: false, type: '' })
  const [isEditing, setIsEditing] = useState(false)
  const [cats] = useState(categories)
  const [category, setCategory] = useState({
    ...defaultCategory,
    workshop_id: workshop.workshop_id,
  })

  useEffect(() => {
    if (service.id > 0)
      setCategory(categories.filter((itm: any) => itm.id === service.category_id)[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let newCategory = categories.filter((cat: any) => cat.name === category.name)[0]
    if (newCategory) service.category_id = newCategory.id

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories])

  const _onSubmitService = (e: FormEvent) => {
    e.preventDefault()
    if (service.id === 0) {
      const { id, ...newService } = service
      onSubmitService('POST', '', newService)
    } else {
      const { type, workshop_id, created_at, ...newService } = service
      onSubmitService('PATCH', service.id, newService)
    }
  }

  const _onSaveCategory = () => {
    setIsEditing(false)
    if (category.id === 0) {
      const { id, ...newCategory } = category
      onSubmitCategory('POST', '', newCategory)
    } else onSubmitCategory('PATCH', category.id.toString(), category)
  }

  const _onConfirmDelete = () => {
    setIsEditing(false)
    setConfirmDelete({ ...confirm, confirm: false, type: '' })
    switch (confirm.type) {
      case 'Category':
        setCategory(defaultCategory)
        onSubmitCategory('DELETE', category.id.toString(), null)
        break
      case 'Service':
        onSubmitService('DELETE', service.id.toString(), null)
        break
    }
  }

  const onCategoryChange = (cat: any) => {
    setCategory({ ...category, name: cat.label, id: cat.value })
    onChange('category_id', cat.value)
  }

  const onChangeInputCategory = (category: any, e: any) => {
    setCategory({ ...category, name: e.target.value })
  }

  const _onChange = ({ name, value }: any) => {
    onChange(name, value)
  }

  return (
    <Dialog>
      <CardContent>
        <h2 className="text-sm mb-xs uppercase font-bold">TJENESTER</h2>
        <form id="servicesForm">
          <div className="flex space-x-md mb-md">
            <div className="w-3/5 flex">
              {isEditing ? (
                <input
                  title="Category"
                  autoFocus
                  value={category?.name}
                  onChange={(e) => onChangeInputCategory(category, e)}
                  type="text"
                  className="border border-gray-light rounded p-xs text-md"
                />
              ) : (
                <Select
                  className="w-4/5"
                  id="category_id"
                  name="category_id"
                  value={{ value: category?.id, label: category?.name }}
                  key="name"
                  options={cats.map((item: any, key: number) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                  onChange={(val) => onCategoryChange(val)}
                ></Select>
              )}
              <ActionToolbar
                category={category}
                isEditing={isEditing}
                onAdd={() => setIsEditing(true)}
                onEdit={() => setIsEditing(true)}
                onSave={_onSaveCategory}
                onDelete={() => setConfirmDelete({ ...confirm, confirm: true, type: 'Category' })}
                onCancel={() => setIsEditing(false)}
              />
            </div>
          </div>
          <div className="flex space-x-md mb-md">
            <div className="w-1/2">
              <SmallTextField
                name="code"
                label="Service code"
                value={service.code}
                required
                onChange={(value) => _onChange({ name: 'code', value })}
              />
            </div>

            <div className="w-1/2">
              <div>
                <SmallTextField
                  id="name"
                  label="Service name"
                  name="name"
                  value={service.name}
                  required
                  onChange={(value) => _onChange({ name: 'name', value })}
                />
              </div>
            </div>
          </div>
          <div className="flex space-x-md mb-md">
            <div className="w-1/2">
              <SmallTextField
                id="duration"
                label="Duration"
                name="duration"
                type="number"
                value={service.duration}
                onChange={(value) => _onChange({ name: 'duration', value })}
              />
              <CheckBox
                label="Active"
                checked={service.is_active}
                onChange={(value: boolean) => _onChange({ name: 'is_active', value })}
              />
            </div>
            <div className="w-1/2">
              <TextArea
                id="description"
                label="Description"
                name="description"
                rows={4}
                value={service.description}
                onChange={(value) => _onChange({ name: 'description', value })}
              />
            </div>
          </div>

          <div className="flex space-x-md mb-md">
            <div className="w-1/2">
              {service.id > 0 && (
                <button
                  className="btn btn-danger"
                  onClick={(e) => {
                    e.preventDefault()
                    setConfirmDelete({ ...confirm, confirm: true, type: 'Service' })
                  }}
                >
                  Slette
                </button>
              )}
            </div>
            <div className="w-1/6">
              <button className="btn btn-default" onClick={onCancel} disabled={saving}>
                Avbryt
              </button>
            </div>
            <div className="w-1/6">
              <button
                className="btn btn-primary"
                onClick={_onSubmitService}
                disabled={saving || service.category_id === 0}
              >
                {saving ? 'Lagrer...' : 'Lagre'}
              </button>
            </div>
          </div>
        </form>
      </CardContent>
      {confirm.confirm && (
        <ConfirmDialog
          title={`Slette ${confirm.type.toLowerCase()}?`}
          description={`Vil du slette denne ${confirm.type.toLowerCase()}?`}
          confirmButtonText="Slett"
          cancelButtonText="Avbryt"
          onCancel={() => setConfirmDelete({ ...confirm, confirm: false, type: '' })}
          onConfirm={_onConfirmDelete}
        />
      )}
    </Dialog>
  )
}
