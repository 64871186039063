import React, { FormEvent, FunctionComponent, useContext, useEffect, useState } from 'react'
import { ToastContext } from '../../../../contexts/ToastContext'
import { TextField } from '../../../shared/Form'
import { Button } from '../../../shared/Buttons'
import { Loading } from '../../../shared/Loading'
import { client } from '../../../../lib/client'
import styled from 'styled-components'

type Props = {
  workshopId: string
}

const Error = styled.div`
  color: var(--error);
`

export const Meta: FunctionComponent<Props> = ({ workshopId }) => {
  const { showToast } = useContext(ToastContext)
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>('idle')
  const [error, setError] = useState<string>('')
  const [metaTitle, setMetaTitle] = useState<string>('')

  useEffect(() => {
    setStatus('pending')

    client(`workshop/${workshopId}/meta`)
      .then(({ title }) => {
        setStatus('idle')
        setMetaTitle(title)
      })
      .catch(() => {
        setError('Det oppstod dessverre en feil ved henting av meta tittel')
        setStatus('failure')
      })
  }, [workshopId])

  const doUpdateMeta = (e: FormEvent) => {
    e.preventDefault()

    setStatus('pending')

    client(`workshop/${workshopId}/meta`, {
      method: 'PATCH',
      body: { title: metaTitle },
    })
      .then(() => {
        setStatus('idle')
        showToast({ type: 'SUCCESS', message: 'Meta ble oppdatert!' })
      })
      .catch(() => {
        showToast({ type: 'ERROR', message: 'Det oppstod dessverre en feil' })
        setStatus('failure')
      })
  }

  return (
    <React.Fragment>
      <h3 className="text-lg mb-md">Nettside meta tittel</h3>

      {status === 'pending' && (
        <div className="flex items-center justify-center">
          <Loading />
        </div>
      )}

      <form onSubmit={doUpdateMeta}>
        <Error>{error}</Error>

        <TextField
          id="meta"
          label="Meta tittel"
          value={metaTitle}
          name="meta"
          onChange={(value) => setMetaTitle(value)}
        />

        <Button type="submit">Lagre</Button>
      </form>
    </React.Fragment>
  )
}
