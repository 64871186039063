import React, { FormEvent, useState } from 'react'
import { Error } from '../../shared/Error'
import { TextField } from '../../shared/Form'
import { client } from '../../../lib/client'

export const LoginPage = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [user, setUser] = useState<{ username: string; password: string }>({
    username: '',
    password: '',
  })

  const onChange = (field: string, value: string) => {
    setUser({ ...user, [field]: value })
  }

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()
    setLoading(true)
    setError('')

    return client(`auth`, {
      method: 'POST',
      body: { username: user.username, password: user.password },
    })
      .then(({ token }) => {
        localStorage.setItem('carsweb-admin-token', token)
        window.location.reload()
      })
      .catch((error) => {
        setLoading(false)
        setError(error.message)
      })
  }

  return (
    <div className="grid grid-cols-12 gap-x-md mt-lg max-width mx-auto">
      <div className="col-span-4 col-start-5">
        <h1 className="text-3xl mb-md">Logg inn</h1>

        {error && <Error>{error}</Error>}

        <form id="loginForm" onSubmit={onSubmit}>
          <TextField
            id="brukernavn"
            label="Brukernavn"
            type="text"
            name="username"
            value={user.username}
            onChange={(newValue) => onChange('username', newValue)}
          />

          <TextField
            id="passord"
            label="Passord"
            type="password"
            name="password"
            value={user.password}
            onChange={(newValue) => onChange('password', newValue)}
          />

          <div className="flex justify-end w-full">
            <button
              className="btn btn-primary"
              type="submit"
              disabled={loading || !user.username || !user.password}
            >
              {loading ? 'Logger inn...' : 'Logg inn'}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
