import React, { FunctionComponent } from 'react'
import { useHistory } from 'react-router-dom'

type Props = {
  workshops: any[]
}

export const WorkshopTable: FunctionComponent<Props> = ({ workshops }) => {
  const history = useHistory()

  if (workshops) {
    return (
      <table className="table" aria-label="verksteder">
        <thead>
          <tr>
            <th>DIST</th>
            <th>Navn</th>
            <th>Grossist</th>
            <th>Kundenr.</th>
            <th>Verkstednr.</th>
            <th>Aktiv</th>
          </tr>
        </thead>
        <tbody>
          {workshops.map((workshop) => (
            <tr
              key={`w-${workshop.workshop_id}`}
              onClick={() => history.push(`/workshops/${workshop.workshop_id}`)}
            >
              <td>{workshop.dist}</td>
              <td>{workshop.name}</td>
              <td>{workshop.wholesaler_name}</td>
              <td>{workshop.customer_number}</td>
              <td>{workshop.workshop_number}</td>
              <td>{workshop.active ? 'Ja' : 'Nei'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  }

  return null
}
