import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'

const ImageWrapper = styled.div`
  position: relative;
  margin-bottom: var(--leading);

  img {
    width: 100%;
    height: auto;
    min-height: 200px;
    transition: opacity 0.25s ease-out;

    &.loading {
      opacity: 0;
    }

    &.loaded {
      opacity: 1;
    }
  }

  button {
    position: absolute;
    left: 0;
    bottom: 0;
  }
`

type Props = {
  image: any
}

export const Image: FunctionComponent<Props> = ({ image, children }) => {
  const [loading, setLoading] = useState(true)

  return (
    <ImageWrapper>
      <img
        className={loading ? 'loading' : 'loaded'}
        alt={`bilde`}
        src={image.image_url}
        onLoad={() => {
          setLoading(false)
        }}
      />

      {!loading && <React.Fragment>{children}</React.Fragment>}

      {loading && <p>Laster bilde...</p>}
    </ImageWrapper>
  )
}
