import React, { FormEvent, FunctionComponent, useState } from 'react'
import { Option, Select, TextField } from '../../../shared/Form'
import { Button } from '../../../shared/Buttons'

type Props = {
  user: any
  onSubmit: (user: any) => void
  cancel: () => void
}

export const UserForm: FunctionComponent<Props> = ({ user, onSubmit, cancel }) => {
  const [username, setUsername] = useState(user.username)
  const [selectedRole, setSelectedRole] = useState(user.role)

  const roles = ['API', 'SUPERUSER', 'WORKSHOP']

  const saveButtonDisabled = !username || !selectedRole

  const _onSubmit = (e: FormEvent) => {
    e.preventDefault()
    onSubmit({ username, role: selectedRole })
  }

  return (
    <form onSubmit={_onSubmit}>
      <div className="flex">
        <div className="w-1/2">
          <div>
            <TextField
              id="brukernavn"
              label="Brukernavn"
              value={username}
              type="text"
              onChange={(value) => setUsername(value)}
              name="username"
            />
          </div>

          <div className="mt-md">
            <Select
              id="wholesaler-select"
              value={selectedRole}
              name="role"
              onChange={(value) => setSelectedRole(value)}
              label="Rolle"
            >
              {roles.map((role) => (
                <Option key={`ws-${role}`} value={role}>
                  {role}
                </Option>
              ))}
            </Select>
          </div>

          <div className="flex mt-md">
            <div className="w-1/2">
              <Button type="button" onClick={cancel}>
                Avbryt
              </Button>
            </div>
            <div className="w-1/2">
              <Button type="submit" disabled={saveButtonDisabled}>
                Lagre
              </Button>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}
