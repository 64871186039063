import React, { FunctionComponent, useContext, useEffect, useState } from 'react'

import { ToastContext } from '../../../../contexts/ToastContext'
import styled from 'styled-components'

import { ServicesForm } from './ServicesForm'
import { SimpleCheckBox } from '../../../shared/form/CheckBox'

import { client } from '../../../../lib/client'

const CategoryContainer = styled.div`
  margin-bottom: 4vh;
`
const CheckBoxes = styled.div`
  display: block;
  label {
    display: block;
    white-space: nowrap;
    cursor: pointer;
  }
`
type Props = {
  workshop: any
}

type ServiceProps = {
  id: number
  category_id: number
  code: string
  name: string
  description: string
  duration: string
  is_active: boolean
}

const defaultValues: ServiceProps = {
  id: 0,
  category_id: 0,
  code: '',
  name: '',
  description: '',
  duration: '',
  is_active: true,
}

export const ServicesPage: FunctionComponent<Props> = ({ workshop }) => {
  const [saving, setSaving] = useState(false)
  const { showToast } = useContext(ToastContext)
  const [categories, setCategories] = useState<any>([])
  let [services, setServices] = useState<any>([])
  const [form, setForm] = useState({ isOpen: false, service: defaultValues })

  useEffect(() => {
    getServices()
    // eslint-disable-next-line
  }, [])

  const getServices = (): any => {
    client(`/services/${workshop.workshop_id}`)
      .then((data) => {
        setCategories(
          data
            .filter((x: any) => x.type === 'category')
            .sort(function (a: any, b: any) {
              return a.id - b.id || a.name.localeCompare(b.name)
            })
        )
        setServices(
          data
            .filter((x: any) => x.type === 'service')
            .sort(function (a: any, b: any) {
              return a.id - b.id || a.name.localeCompare(b.name)
            })
        )
      })
      .catch((error) => console.log(error))
  }

  const getCategories = (): any => {
    client(`services/categories/${workshop.workshop_id}`)
      .then((data) => {
        setCategories(data)
      })
      .catch((error) => console.log(error))
  }

  const onSubmitService = (type: string, serviceId: string, service: any) => {
    setSaving(true)

    client(`services/${serviceId}`, {
      method: type,
      body: service,
    })
      .then(() => {
        setSaving(false)
        setForm({ ...form, isOpen: false })
        getServices()
        showToast({ type: 'SUCCESS', message: type === 'DELETE' ? 'Slettet' : 'Lagret!' })
      })
      .catch(() => {
        setSaving(false)
        showToast({ type: 'ERROR', message: 'Det oppstod dessverre en feil' })
      })
  }

  const onSubmitCategory = (type: string, catId: string, category: any) => {
    // check if category contains services
    if (type === 'DELETE') {
      var hasServices =
        services.filter((itm: any) => itm.category_id.toString() === catId).length > 0
      if (hasServices) {
        return showToast({
          type: 'ERROR',
          message: 'Category has related services and cannot be deleted.',
        })
      }
    }

    setSaving(true)

    client(`services/categories/${catId}`, {
      method: type,
      body: category,
    })
      .then(() => {
        setSaving(false)
        // getServices()
        getCategories()
        showToast({ type: 'SUCCESS', message: type === 'DELETE' ? 'Slettet' : 'Lagret!' })
      })
      .catch(() => {
        setSaving(false)
        showToast({ type: 'ERROR', message: 'Det oppstod dessverre en feil' })
      })
  }

  const onChange = (name: string, value: any) => {
    setForm({ ...form, service: { ...form.service, [name]: value } })
  }

  return (
    <React.Fragment>
      <div className="grid grid-cols-3">
        <div className="col-span-2 grid grid-cols-2">
          {categories.map((category: any, key: number) => {
            return (
              <CategoryContainer key={key} className="col-span-1">
                <h1 className="text-xl mb-xs" title="Click to edit">
                  {category.name}
                </h1>
                <CheckBoxes>
                  {services
                    .filter((itm: any) => itm.category_id === category.id)
                    .map((service: any, indx: number) => {
                      return (
                        <SimpleCheckBox
                          label={service.name}
                          key={service.id}
                          checked={service.is_active}
                          onClick={() => setForm({ isOpen: !form.isOpen, service: service })}
                        />
                      )
                    })}
                </CheckBoxes>
              </CategoryContainer>
            )
          })}
        </div>
        <div className="col-span-1">
          <button
            className="btn btn-primary"
            onClick={() => {
              setForm({ isOpen: !form.isOpen, service: defaultValues })
            }}
          >
            New Service
          </button>
        </div>
      </div>

      {form.isOpen && (
        <ServicesForm
          workshop={workshop}
          categories={categories}
          service={form.service}
          saving={saving}
          onChange={onChange}
          onSubmitService={onSubmitService}
          onSubmitCategory={onSubmitCategory}
          onCancel={() => setForm({ isOpen: false, service: defaultValues })}
        />
      )}
    </React.Fragment>
  )
}
