import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import { CheckBox } from '../../../shared/form/CheckBox'
import { ConfirmDialog } from '../../../shared/modal/Confirm'

const Field = styled.div`
  label {
    display: block;
  }

  &:not(:first-child) {
    margin-left: 1rem;
  }
`

const CheckBoxes = styled.div`
  display: flex;
`

const OpeningHoursLine = styled.div`
  display: flex;
  padding: var(--spacing-lg) 0;
  justify-content: space-between;
  align-items: center;
`

const availableWeekdays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
]

type Props = {
  workshop: any
  onDataChanged: ({ opening_hours: any }: any) => void
}

export const StructuredData: FunctionComponent<Props> = ({ workshop, onDataChanged }) => {
  const [openingHoursSpecification, setOpeningHoursSpecification] = useState<any>(
    () => workshop.opening_hours?.openingHoursSpecification ?? []
  )
  const [confirmDeleteLine, setConfirmDeleteLine] = useState<any>(null)

  const save = () => {
    onDataChanged({
      opening_hours: {
        openingHoursSpecification: openingHoursSpecification,
      },
    })
  }

  const updateOpeningHoursSpecificationDays = (
    lineNum: number,
    dayOfWeek: string,
    isChecked: boolean
  ) => {
    const checkedDays = openingHoursSpecification[lineNum].dayOfWeek

    const updatedDaysOfWeek = isChecked
      ? [...checkedDays, dayOfWeek]
      : checkedDays.filter((dow: string) => dow !== dayOfWeek)

    const newState = openingHoursSpecification.map((l: any, index: number) =>
      index === lineNum ? { ...l, dayOfWeek: updatedDaysOfWeek } : l
    )

    setOpeningHoursSpecification(newState)
  }

  const updateTime = (lineNum: number, key: string, value: string) => {
    const newState = openingHoursSpecification.map((l: any, index: number) =>
      index === lineNum ? { ...l, [key]: value } : l
    )

    setOpeningHoursSpecification(newState)
  }

  const deleteLine = () => {
    setConfirmDeleteLine(null)
    const newState = [...openingHoursSpecification]
    newState.splice(confirmDeleteLine, 1)
    setOpeningHoursSpecification(newState)
    onDataChanged({
      opening_hours: {
        openingHoursSpecification: newState,
      },
    })
  }

  return (
    <React.Fragment>
      <div className="flex items-center justify-between mb-md">
        <div>
          <h1 className="text-xl mb-xs">Åpningstider</h1>
          <p>Legg til dager med samme åpningstid på samme linje</p>
        </div>

        <button className="btn btn-primary" onClick={save}>
          Lagre
        </button>
      </div>

      {openingHoursSpecification.map((ohs: any, i: number) => (
        <OpeningHoursLine key={`ohs-${i}`}>
          <div>
            <CheckBoxes>
              {availableWeekdays.map((dow, i2) => (
                <CheckBox
                  label={dow}
                  key={`dow-${i2}`}
                  checked={ohs.dayOfWeek.indexOf(dow) !== -1}
                  onChange={(isChecked: boolean) =>
                    updateOpeningHoursSpecificationDays(i, dow, isChecked)
                  }
                />
              ))}
            </CheckBoxes>

            <div className="flex space-x-md mt-sm">
              <Field>
                <label className="text-gray">Åpner</label>
                <input
                  type="time"
                  value={ohs.opens}
                  onChange={({ target: { value } }) => updateTime(i, 'opens', value)}
                />
              </Field>

              <Field>
                <label className="text-gray">Stenger</label>
                <input
                  type="time"
                  value={ohs.closes}
                  onChange={({ target: { value } }) => updateTime(i, 'closes', value)}
                />
              </Field>
            </div>
          </div>

          <button className="btn btn-danger-outline" onClick={() => setConfirmDeleteLine(i)}>
            Slett linje
          </button>
        </OpeningHoursLine>
      ))}

      <button
        className="btn w-full mt-md"
        onClick={() =>
          setOpeningHoursSpecification([
            ...openingHoursSpecification,
            { dayOfWeek: [], opens: '07:00', closes: '16:00' },
          ])
        }
      >
        Ny linje
      </button>

      {confirmDeleteLine !== null ? (
        <ConfirmDialog
          title="Vil du slette denne linjen?"
          confirmButtonText="Slett"
          cancelButtonText="Avbryt"
          onConfirm={deleteLine}
          onCancel={() => setConfirmDeleteLine(null)}
        />
      ) : null}
    </React.Fragment>
  )
}
