import React, { FunctionComponent, useState } from 'react'
import { ConfirmDialog } from '../../../shared/modal/Confirm'
import { PageList } from './PageList'

type Props = {
  updatingPageId: string
  pages: any[]
  onChange: (page: any) => void
  onDelete: (page: any) => void
  removeParentPage: (pageId: string) => void
}

export const CollectionPagesHierarchy: FunctionComponent<Props> = ({
  updatingPageId,
  pages,
  onChange,
  onDelete,
  removeParentPage,
}) => {
  const [pageToUpdate, setPageToUpdate] = useState<any>(null)
  const [pageToDelete, setPageToDelete] = useState<any>(null)

  const onUpdate = () => {
    onChange(pageToUpdate)
    setPageToUpdate(null)
  }

  const onConfirmDelete = () => {
    onDelete(pageToDelete)
    setPageToDelete(null)
  }

  const parents = pages.filter((page) => page.parent_id === null)

  return (
    <React.Fragment>
      <PageList
        all={pages}
        pages={parents}
        removeParentPage={removeParentPage}
        onDelete={(page) => setPageToDelete(page)}
        updatePage={(page) => setPageToUpdate(page)}
      />

      {pageToUpdate && (
        <ConfirmDialog
          title="Oppdatere overordnet side?"
          onConfirm={onUpdate}
          onCancel={() => setPageToUpdate(null)}
          onClose={() => setPageToUpdate(null)}
        />
      )}

      {pageToDelete && (
        <ConfirmDialog
          title={`Slette ${pageToDelete.name}?`}
          onConfirm={onConfirmDelete}
          onCancel={() => setPageToDelete(null)}
          onClose={() => setPageToDelete(null)}
        />
      )}
    </React.Fragment>
  )
}
