import { config } from '../config'

const localStorageKey = 'carsweb-admin-token'

export function client(endpoint: string, { body, ...customConfig }: any = {}) {
  const token = window.localStorage.getItem(localStorageKey)
  const headers: HeadersInit = { 'Content-Type': 'application/json' }

  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  const c = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  }

  if (body) {
    c.body = JSON.stringify(body)
  }

  return window.fetch(`${config.API_URL}${endpoint}`, c).then(async (response) => {
    if (response.status === 401) {
      logout()
      // @ts-ignore
      window.location.assign(window.location)
      return
    }

    if (response.ok) {
      try {
        return await response.json()
      } catch (e) {
        return ''
      }
    } else {
      const data = await response.json()
      return Promise.reject(data)
    }
  })
}

function logout() {
  window.localStorage.removeItem(localStorageKey)
}
