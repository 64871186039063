import React, { FunctionComponent, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Loading } from '../../../shared/Loading'
import { client } from '../../../../lib/client'

type Consumer = {
  id: string
  type: 'wholesaler' | 'workshop'
  code: string
  name: string
  has_free_text?: string // Only workshops
  image_count?: string // Only workshops
}

type Props = {
  appName: string
  consumers: Consumer[]
}

export const ConsumerTable: FunctionComponent<Props> = ({ appName, consumers }) => {
  const history = useHistory()
  const [message, setMessage] = useState<{ type: 'SUCCESS' | 'ERROR'; text: string } | null>(null)
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>('idle')
  const [updatingWholesalerId, setUpdatingWholesalerId] = useState<string>()

  const updateADItems = async (wholesalerId: string) => {
    try {
      setStatus('pending')
      setUpdatingWholesalerId(wholesalerId)

      await client(`wholesale/${wholesalerId}/ad-items`, {
        method: 'PATCH',
      })

      setStatus('idle')
      setMessage({ type: 'SUCCESS', text: 'Oppdatering initialisert!' })
      setUpdatingWholesalerId('')

      setTimeout(() => setMessage(null), 3000)
    } catch (e) {
      setStatus('failure')
      setMessage({ type: 'ERROR', text: 'Det oppstod dessverre en feil.' })
    }
  }

  if (consumers && consumers.length > 0) {
    const workshops = consumers
      .filter((consumer) => consumer.type === 'workshop')
      .map((consumer, i) => {
        return (
          <tr
            key={`work-${i + 1}`}
            onClick={() => history.push(`/${consumer.type}s/${consumer.id}`)}
          >
            <td>{consumer.code}</td>
            <td>{consumer.name}</td>
            <td>{consumer.image_count}</td>
            <td>{consumer.has_free_text ? 'Ja' : 'Nei'}</td>
          </tr>
        )
      })

    const consumerIsWholesaler = (consumer: Consumer) => consumer.type === 'wholesaler'

    const wholesalers = consumers.filter(consumerIsWholesaler).map((consumer, i) => {
      const isUpdatingThisWholesaler = updatingWholesalerId === consumer.id && status === 'pending'

      return (
        <tr key={`whol-${i + 1}`}>
          <td>{consumer.code}</td>
          <td>{consumer.name}</td>
          <td style={{ textAlign: 'right' }}>
            {appName.toLowerCase() === 'bestilldeler' ? (
              <button
                className="btn btn-sm"
                disabled={status === 'pending'}
                onClick={() => updateADItems(consumer.id)}
              >
                {isUpdatingThisWholesaler ? <Loading size="small" /> : 'Oppdater fra AD prisfil'}
              </button>
            ) : appName.toLowerCase() === 'dekksøk' ? (
              <button
                className="btn"
                onClick={() => history.push(`/wholesalers/${consumer.id}/update-tires`)}
              >
                Vis dekkfiler
              </button>
            ) : null}
          </td>
        </tr>
      )
    })

    return (
      <React.Fragment>
        {message && (
          <div
            className={`${message.type === 'SUCCESS' ? 'text-success' : 'text-error'} font-bold`}
          >
            {message.text}
          </div>
        )}

        <div className="flex flex-col gap-x-md">
          {appName !== 'bilxtraweb' && (
            <React.Fragment>
              <h3 className="uppercase text-sm font-bold mt-md">Grossister</h3>

              <table className="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Navn</th>
                  </tr>
                </thead>
                <tbody>{wholesalers}</tbody>
              </table>
            </React.Fragment>
          )}

          <h3 className="uppercase text-sm font-bold mt-md">Verksted</h3>
          <table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th>Navn</th>
                <th>Bilder</th>
                <th>Tekst</th>
              </tr>
            </thead>
            <tbody>{workshops}</tbody>
          </table>
        </div>
      </React.Fragment>
    )
  }

  return <p>Ingen brukere</p>
}
