import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { PageHeader } from '../../../shared/PageHeader'
import { Error } from '../../../shared/Error'
import { WorkshopFormV2 } from '../common/WorkshopFormV2'
import { client } from '../../../../lib/client'

const emptyWorkshop = {
  name: '',
  slug: '',
  dist: '',
  wholesaler_id: '',
  workshop_number: '',
  customer_number: '',
  email: '',
  phone: '',
  street: '',
  zip: '',
  city: '',
  county: '',
  lat: '',
  long: '',
  facebook: '',
  homepage: '',
}

const getNonEmptyValues = (values: any) => {
  let data = {}
  const nonEmptyValues = Object.keys(values).filter(
    (key) => values[key] !== null && values[key] !== ''
  )
  Object.values(nonEmptyValues).map((key) => (data = { ...data, [key]: values[key] }))

  return data
}

export const WorkshopCreatePage = () => {
  const history = useHistory()
  const [status, setStatus] = useState('idle')

  const onSubmit = (newWorkshop: any) => {
    setStatus('pending')

    const data = getNonEmptyValues(newWorkshop)

    client(`workshop`, { method: 'POST', body: data })
      .then(({ workshop_id }) => {
        setStatus('idle')
        history.push(`/workshops/${workshop_id}`)
      })
      .catch(() => setStatus('failure'))
  }

  return (
    <React.Fragment>
      <PageHeader title="Nytt verksted" />
      {status === 'failure' && <Error>Det oppstod dessverre en feil.</Error>}
      {status === 'pending' && <p>Oppretter verksted...</p>}
      <WorkshopFormV2
        initialWorkshop={emptyWorkshop}
        saving={status === 'pending'}
        onSubmit={onSubmit}
        cancel={() => history.goBack()}
      />
    </React.Fragment>
  )
}
