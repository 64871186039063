import React, { useEffect, useState } from 'react'
import { WorkshopTable } from '../common/WorkshopTable'
import { PageHeader } from '../../../shared/PageHeader'
import { Error } from '../../../shared/Error'
import { Filter } from '../../../shared/Filter'
import { Loading } from '../../../shared/Loading'
import { client } from '../../../../lib/client'

export const WorkshopPage = () => {
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>('idle')
  const [workshops, setWorkshops] = useState<any[]>([])
  const [filteredWorkshops, setFilteredWorkshops] = useState<any[]>([])

  useEffect(() => {
    setStatus('pending')

    client(`workshop`)
      .then((result) => {
        setStatus('idle')
        setWorkshops(result)
        setFilteredWorkshops(result)
      })
      .catch(() => setStatus('failure'))
  }, [])

  return (
    <div className="grid grid-cols-12 gap-x-md max-width mx-auto mt-md">
      <PageHeader title="Verksteder" newPath="/workshops/create" />

      {status === 'pending' && (
        <div className="col-span-12 mt-md flex items-center justify-center">
          <Loading />
        </div>
      )}

      {status === 'failure' && <Error className="col-span-12">Det oppstod dessverre en feil</Error>}

      {workshops.length > 0 && (
        <React.Fragment>
          <div className="col-span-3">
            <Filter
              data={workshops}
              filterChanged={(filtered) => {
                setFilteredWorkshops(filtered)
              }}
            />
          </div>

          <div className="col-span-12">
            <WorkshopTable workshops={filteredWorkshops} />
          </div>
        </React.Fragment>
      )}
    </div>
  )
}
