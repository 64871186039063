import React, { FunctionComponent } from 'react'
import styled, { keyframes } from 'styled-components'

const rotateKeyFrames = keyframes`
  from {
    transform: rotate(0deg);
  }
  
  to {
    transform: rotate(360deg);
  }
`

const sizeMap = {
  small: '20px',
  medium: '35px',
  large: '65px',
}

const borderSizeMap = {
  small: '2px',
  medium: '4px',
  large: '6px',
}

type SpinnerProps = {
  size: 'small' | 'medium' | 'large'
  circleColor: string
  spinnerColor: string
}

const Spinner = styled.div`
  width: ${({ size }: SpinnerProps) => sizeMap[size]};
  height: ${({ size }: SpinnerProps) => sizeMap[size]};
  background-color: transparent;
  border-radius: 50%;
  border: ${({ size }: SpinnerProps) => borderSizeMap[size]} solid
    ${({ circleColor }) => circleColor};
  border-top-color: ${({ spinnerColor }: SpinnerProps) => spinnerColor};
  animation: ${rotateKeyFrames} 1s cubic-bezier(0.68, 0.31, 0.28, 0.63) 0s infinite;
`

type Props = {
  className?: string
  style?: CSSStyleDeclaration
  size?: 'small' | 'medium' | 'large'
  circleColor?: string
  spinnerColor?: string
}

export const Loading: FunctionComponent<Props> = ({
  className,
  style,
  size,
  circleColor = '#FFFFFF',
  spinnerColor = '#2F394D',
}) => {
  const styles = style ?? {}

  return (
    <div className={className} style={styles}>
      <Spinner size={size ?? 'large'} circleColor={circleColor} spinnerColor={spinnerColor} />
    </div>
  )
}
