import React, { FunctionComponent, useContext, useState } from 'react'
import { WorkshopFormV2 } from '../common/WorkshopFormV2'
import { ToastContext } from '../../../../contexts/ToastContext'
import { useHistory } from 'react-router'
import { client } from '../../../../lib/client'

type Props = {
  workshop: any
}

export const WorkshopDetailForm: FunctionComponent<Props> = ({ workshop }) => {
  const history = useHistory()
  const { showToast } = useContext(ToastContext)
  const [saving, setSaving] = useState(false)

  const onSubmit = (state: any) => {
    const nonEmptyValues = Object.keys(state).filter(
      (key) => state[key] !== null && state[key] !== undefined
    )

    let values = {}
    Object.values(nonEmptyValues).map((key) => {
      if (key === 'wholesaler') {
        return (values = { ...values, wholesaler_id: parseInt(state[key]) })
      } else {
        return (values = { ...values, [key]: state[key] })
      }
    })

    setSaving(true)

    client(`workshop/${workshop.workshop_id}`, {
      method: 'PATCH',
      body: values,
    })
      .then(() => {
        setSaving(false)
        showToast({ type: 'SUCCESS', message: 'Lagret!' })
      })
      .catch(() => {
        setSaving(false)
        showToast({ type: 'ERROR', message: 'Det oppstod dessverre en feil' })
      })
  }

  return (
    workshop && (
      <WorkshopFormV2
        initialWorkshop={workshop}
        saving={saving}
        cancel={() => history.goBack()}
        onSubmit={onSubmit}
      />
    )
  )
}
