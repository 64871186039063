import React, { FunctionComponent } from 'react'
import { useHistory } from 'react-router-dom'
import { Breadcrumbs } from './Breadcrumbs'

type Props = {
  title: string
  newPath?: string
  parent?: { path: string; title: string }
}

export const PageHeader: FunctionComponent<Props> = ({ title, newPath, parent }) => {
  const history = useHistory()

  return (
    <div className="col-span-12 mb-sm">
      {parent ? <Breadcrumbs parent={parent} title={title} aria-label="breadcrumb" /> : null}

      <div className="flex justify-between items-center">
        <h1 className="heading-2xl" style={{ marginBottom: 0 }}>
          {title}
        </h1>

        {newPath ? (
          <button className="btn btn-primary" onClick={() => history.push(newPath)}>
            Ny
          </button>
        ) : null}
      </div>
    </div>
  )
}
