import React from 'react'
import { PageHeader } from '../../shared/PageHeader'

export const HelpPage = () => {
  return (
    <div className="max-width mx-auto mt-md">
      <PageHeader title="Brukerveiledning" />

      <section>
        <h1 className="heading-xl">Opprette nytt verksted</h1>
        <p className="paragraph">
          For å opprette et nytt verksted gå til Verksteder og klikk på "Ny" knappen. Ved å velge
          "Grossist" fra nedtrekksmenyen kobles verkstedet mot grossist.
        </p>
        <p className="paragraph">
          For å opprette BilXtra verksted side, tilgang til Dekksøk eller Bestilldeler.no så må
          verkstedet knyttes opp til disse. Det gjøres ved å gå inn på det aktuelle verkstedet,
          velge fliken "Apper" og legge til appen du ønsker verkstedet skal ha tilgang til.
        </p>
      </section>

      <section>
        <h1 className="heading-xl">BilXtra verksted side</h1>
        <p className="paragraph">
          For å opprette BilXtra verksted side så må verkstedet knyttes opp til BilXtra verksted
          "appen". Det gjøres ved å gå inn på det aktuelle verkstedet, velge fliken "Apper" og legge
          til BilXtra verksted fra listen. Siden er "aktiv" dersom den ligger i tabellen under
          "Tilgjengelige apper".
        </p>
        <p className="paragraph">
          <em>
            MERK! BilXtra verksted sidene er statiske, dvs. de må bygges på nytt for hver endring.
            Dette skjer automatisk hver kveld kl. 22.
          </em>
        </p>
        <p className="paragraph">
          Det er en del informasjon som bør legges inn på verkstedet for at siden skal fungere godt:
        </p>
        <ul className="mb-md">
          <li>Addresse, e-post og telefonnummer</li>
          <li>
            Lengdegrad / breddegrad - slik at verkstedet dukker opp på kartet. Disse koordinatene
            kan hentes ut ved hjelp av f.eks. Google Maps.
          </li>
          <li>Meta tittel - den legges inn under fliken "Innstillinger"</li>
          <li>
            EL_APPROVED må hukes av under "Innstillinger" dersom verkstedet er godkjent el-bil
            verksdted. Da dukker el-bil merket opp på siden.
          </li>
          <li>Bilder til nettsiden kan lastes opp under flinken "Bilder"</li>
        </ul>
      </section>

      <section>
        <h1 className="heading-xl">Webordre på e-post / inn i Cars</h1>

        <h2 className="heading-lg">På e-post</h2>
        <p className="paragraph">
          Dersom verkstedet skal motta e-post for nye ordre via nettsiden så må "SEND_EMAIL" hukes
          av under innstillinger
        </p>

        <h2 className="heading-lg">Inn i tavlen i Cars:</h2>
        <p className="paragraph">
          Dersom verkstedet skal ha ordre inn i tavlen i Cars så må DIST kode legges til på
          verkstedet og "SEND_EMAIL" må <strong>ikke</strong> være avhuket.
        </p>

        <h2 className="heading-lg">Samlesider</h2>
        <p className="paragraph">
          Under fliken "Samlesider" kan verktedet knyttes opp mot en eller flere samlesider.
        </p>
        <p className="paragraph">
          Dersom siden du ønsker ikke finnes så kan denne opprettes under siden "Samlesider" i
          hovedmenyen.
        </p>
      </section>

      <section>
        <h1 className="heading-xl">Bestilldeler.no / Dekksøk</h1>

        <h2 className="heading-lg">For grossist</h2>
        <p className="paragraph">
          Appen må legges til grossisten under fliken "Apper" inne på grossist.
        </p>

        <h2 className="heading-lg">For verksted</h2>
        <p className="paragraph">
          For å legge til Bestilldeler eller Dekksøk så må først verkstedet ha kundenr. og
          verkstednr. Deretter må appen legges til under fliken "Apper" inne på verkstedet.
        </p>
        <p className="paragraph">Kundenummer er deres kundenummer hos grossist.</p>
        <p className="paragraph">
          Verkstednummer er et <strong>unikt</strong> nummer tildelt av oss.
        </p>
      </section>

      <section>
        <h1 className="heading-xl">Brukere</h1>
        <p className="paragraph">
          Nye brukere til adminpanelet kan legges inn under "Brukere". Velg "SUPERUSER" som rolle.
        </p>
        <p className="paragraph">
          For å få opp passord til brukeren, klikk på "Lag nytt passord" knappen i listen over
          brukere.
        </p>
        <p className="paragraph">
          <em>
            Merk! Passordet må <strong>ikke</strong> sendes på e-post.
          </em>
        </p>
      </section>
    </div>
  )
}
