import React, { FunctionComponent, useContext, useState } from 'react'
import generatePassword from 'password-generator'
import { ToastContext } from '../../../../contexts/ToastContext'
import { client } from '../../../../lib/client'

type Props = {
  users: any[]
}

export const UserTable: FunctionComponent<Props> = ({ users }) => {
  const [status, setStatus] = useState('idle')
  const [newPassword, setNewPassword] = useState<{ user: any; newPswd: string } | undefined>(
    undefined
  )
  const { showToast } = useContext(ToastContext)

  const setUserPassword = async (user: any) => {
    try {
      setStatus('pending')

      const newPswd = generatePassword(8, false)
      setNewPassword({ user, newPswd })

      await client(`user/${user.user_id}/set-password`, {
        method: 'PATCH',
        body: { password: newPswd },
      })

      setStatus('idle')
      showToast({ type: 'SUCCESS', message: `Passordet ble endret for ${user.username}!` })
    } catch (e) {
      setStatus('failure')
      showToast({ type: 'ERROR', message: 'Det oppstod dessverre en feil.' })
    }
  }

  return (
    <table className="table">
      <thead>
        <tr>
          <th>Brukernavn</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {users
          .sort((a, b) => (a.username < b.username ? -1 : 1))
          .map((user) => {
            const isUpdatingUser = newPassword && newPassword.user.user_id === user.user_id

            return (
              <tr>
                <td>{user.username}</td>
                <td style={{ textAlign: 'right' }}>
                  {isUpdatingUser ? (
                    <div>Nytt passord: {newPassword?.newPswd}</div>
                  ) : (
                    <button className="btn" onClick={() => setUserPassword(user)}>
                      {status === 'pending' && isUpdatingUser ? '...' : 'Lag nytt passord'}
                    </button>
                  )}
                </td>
              </tr>
            )
          })}
      </tbody>
    </table>
  )
}
