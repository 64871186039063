import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Loading } from '../../../shared/Loading'
import { PageHeader } from '../../../shared/PageHeader'
import { ConfirmDialog } from '../../../shared/modal/Confirm'
import { Error } from '../../../shared/Error'
import { client } from '../../../../lib/client'
import { ToastContext } from '../../../../contexts/ToastContext'
import { TextField } from '../../../shared/Form'
import { CheckBox } from '../../../shared/form/CheckBox'

const workshopHasCollectionPage = (id: string, w: any) =>
  w.collection_pages.indexOf(parseInt(id, 10)) === -1
const workshopHasNotCollectionPage = (id: string, w: any) =>
  w.collection_pages.indexOf(parseInt(id, 10)) !== -1

export const CollectionPageDetail = () => {
  const { id } = useParams<{ id: string }>()
  const { showToast } = useContext(ToastContext)
  const [status, setStatus] = useState('pending')
  const [workshopToAdd, setWorkshopToAdd] = useState<any | null>(null)
  const [workshopToRemove, setWorkshopToRemove] = useState<any | null>(null)
  const [page, setPage] = useState<any | null>(null)
  const [allWorkshops, setAllWorkshops] = useState<any[]>([])
  const [editedName, setEditedName] = useState<string>('')
  const [editedHidden, setEditedHidden] = useState<boolean>(false)

  useEffect(() => {
    Promise.all([client(`workshop?appId=1`), client(`collection-page/${id}`)])
      .then((results) => {
        const workshops = results[0]
        setAllWorkshops(workshops)
        setPage(results[1])
        setEditedName(results[1].name)
        setEditedHidden(results[1].hidden)
        setStatus('idle')
      })
      .catch((error) => {
        setStatus('failure')
      })
  }, [id])

  const addWorkshop = () => {
    const workshop = workshopToAdd
    setWorkshopToAdd(null)

    client(`collection-page/${id}/workshop`, {
      method: 'POST',
      body: { workshop_id: workshop.workshop_id },
    })
      .then(() => {
        setAllWorkshops(
          allWorkshops.map((w) =>
            w.workshop_id === workshop.workshop_id
              ? { ...w, collection_pages: [...w.collection_pages, parseInt(id, 10)] }
              : w
          )
        )
      })
      .catch(() => setStatus('failure'))
  }

  const updatePage = () => {
    client(`collection-page/${id}`, {
      method: 'PATCH',
      body: { name: editedName, hidden: editedHidden },
    })
      .then((response) => {
        setPage(response)
        showToast({ type: 'SUCCESS', message: 'Siden ble oppdatert!' })
      })
      .catch(() =>
        showToast({ type: 'ERROR', message: 'Det oppstod en feil ved oppdatering av siden' })
      )
  }

  const removeWorkshop = () => {
    const workshop = workshopToRemove
    setWorkshopToRemove(null)

    client(`collection-page/${id}/workshop`, {
      method: 'DELETE',
      body: { workshop_id: workshop.workshop_id },
    })
      .then(() => {
        showToast({ type: 'SUCCESS', message: 'Siden ble slettet' })

        setAllWorkshops(
          allWorkshops.map((w) => {
            if (w.workshop_id === workshop.workshop_id) {
              return {
                ...w,
                collection_pages: workshop.collection_pages.filter((cp: any) => cp.page_id === id),
              }
            } else {
              return w
            }
          })
        )
      })
      .catch(() => setStatus('failure'))
  }

  const availableWorkshops = allWorkshops.filter((w) => workshopHasCollectionPage(id, w)) ?? []
  const addedWorkshops = allWorkshops.filter((w) => workshopHasNotCollectionPage(id, w)) ?? []

  return status === 'failure' ? (
    <Error>Det oppstod dessverre en feil.</Error>
  ) : status === 'pending' ? (
    <Loading />
  ) : (
    <React.Fragment>
      <PageHeader
        title={page.name}
        parent={{ path: '/collection-pages', title: 'Alle samlesider' }}
      />

      {page && (
        <React.Fragment>
          <TextField label="Navn" name="name" value={editedName} onChange={setEditedName} />
          <CheckBox label="Skjult" checked={editedHidden} onChange={setEditedHidden} />

          <div>
            <button
              className="btn"
              style={{ marginTop: '1rem' }}
              disabled={!editedName}
              onClick={updatePage}
            >
              Oppdater side
            </button>
          </div>
        </React.Fragment>
      )}

      <h2 className="text-lg">Verksteder</h2>

      <div className="flex">
        <div className="w-1/2">
          <h3 className="text-md" id="addedWorkshopsHeader">
            Lagt til
          </h3>
          <table className="table" aria-labelledby="addedWorkshopsHeader">
            <tbody>
              {addedWorkshops.map((workshop) => (
                <tr>
                  <td>{workshop.name}</td>
                  <td className="text-right">
                    <button
                      className="btn btn-danger"
                      onClick={() => setWorkshopToRemove(workshop)}
                    >
                      Fjern
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="w-1/2">
          <h3 className="text-md" id="availableWorkshopsHeader">
            Tilgjengelige
          </h3>
          <table className="table" aria-labelledby="availableWorkshopsHeader">
            <tbody>
              {availableWorkshops.map((workshop) => (
                <tr>
                  <td>{workshop.name}</td>
                  <td style={{ textAlign: 'right' }}>
                    <button className="btn" onClick={() => setWorkshopToAdd(workshop)}>
                      Legg til
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {workshopToAdd && (
        <ConfirmDialog
          title={`Legge til ${workshopToAdd.name} til ${page.name}?`}
          onConfirm={addWorkshop}
          onCancel={() => setWorkshopToRemove(null)}
        />
      )}

      {workshopToRemove && (
        <ConfirmDialog
          title={`Fjerne ${workshopToRemove.name} fra ${page.name}?`}
          onConfirm={removeWorkshop}
          onCancel={() => setWorkshopToRemove(null)}
        />
      )}
    </React.Fragment>
  )
}
